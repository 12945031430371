import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import qs from 'qs';
import {Switch, Link, Route} from 'react-router-dom';
import SiteNav, {ContentGroup} from 'react-site-nav'; // 1. Do this
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import "../styles/attendanceTime.css"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PrimarySearchAppBar from '../components/secNav';
import Navbar from '../components/navbar';
function AttendanceMain() {
	const API = process.env.REACT_APP_API

	const [value, setValue] = React.useState('1');
	const [attendaners, setAttenders] = useState()

	const handleChange = (event, newValue) => {
		setValue(newValue);
	}




	const useStyles = makeStyles({
		tabs: {
	  
		  "& .MuiTabs-indicator": {
			backgroundColor: "#187bcd",
			height: 3,
		  },
		  "& .MuiTab-root.Mui-selected": {
			color: '#187bcd'
		  }
		}
	  })
	  const classes = useStyles();
	return (


		<>
		<Navbar />
			<Box sx={{ width: '60%', typography: 'body1', margin: "50px auto" }}>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
						<TabList  
						    className={classes.tabs}
						sx={{ margin: "0 auto", }} onChange={handleChange} aria-label="lab API tabs example">
														<Tab sx={{ margin: "0 auto", fontWeight: "bold", color: "black" }} label="All" component={Link} to="all" />

							<Tab sx={{ margin: "0 auto", fontWeight: "bold", color: "black" }} label="Gym" component={Link} to="gym" />
							<Tab sx={{ margin: "0 auto", fontWeight: "bold", color: "black" }} label="Class"   component={Link} to="class"  />
							<Tab sx={{ margin: "0 auto", fontWeight: "bold", color: "black" }} label="Academy" component={Link} to="academy" />

						</TabList>
					</Box>
						<Outlet />
						
				
				</TabContext>
			</Box>


		</>

	)
}


export default AttendanceMain