import { useState } from 'react'
import moment from 'moment-timezone';
import { Box } from '@mui/material';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

function PreCheckInDialog({
	CheckIcon,
	subInfo,
	customerInfo,
	IconButton,
	Button,
	BootstrapDialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CloseIcon,
	ErrorIcon,
	Checkbox,
	Container,
	ColumnLayout,
	GoPackage,
	DateRangeIcon,
	FaRegistered,
	PaidIcon,
	CancelPresentationIcon,
	LoadingButton,
	handleClose,
	customerID,
	render,
	rerender,
	toast,
	open,
	loggedBranch,
	inActiveCount
}) {



	const DateOFToday = moment.tz('Africa/Cairo').format('YYYY-MM-DD')
	const RemainingDays = Math.abs(new Date(subInfo[0]?.EndDate).valueOf() - new Date(DateOFToday).valueOf()) / (1000 * 60 * 60 * 24)
	const [checked, setChecked] = useState(false);
	const [ConfirmButtonLoading, setConfirmButtonLoading] = useState(false);
	const API = process.env.REACT_APP_API
	const isExpired = new Date(DateOFToday).valueOf() > new Date(subInfo[0]?.EndDate).valueOf();
	const hasGraceSession = subInfo[0]?.grace_session == "1";
	const noRemainingSessions = subInfo[0]?.RemainingSessions == "0";
	const isInactiveCountZero = inActiveCount == 0;
	const shouldRenderBox = (isExpired && hasGraceSession && isInactiveCountZero) ||
		(noRemainingSessions && hasGraceSession && isInactiveCountZero)
		;
	

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	function handleCustomerCheckingIn(e) {
		e.preventDefault(); // Prevent default behavior
		setConfirmButtonLoading(true);

		fetch(`${API}/checkUpCusomer`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'CustomerID': customerID,
				'branch': loggedBranch,
				"graceSession": checked
			})
		})
			.then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					rerender(!render);
					toast.success(result.Message, {
						position: "top-center",
						autoClose: 2000,
					});
					handleClose(); // Close the dialog after success
				} else {
					toast.error(result.Message, {
						position: "top-center",
						autoClose: false,
					});
					handleClose(); 
				}
			})
			.catch(error => {
				console.error("Error:", error);
			})
			.finally(() => {
				setConfirmButtonLoading(false); // Ensure loading state is reset
			});
	}

	return (
		<div>
			{customerInfo.length == 0 || subInfo == 0 ? (<BootstrapDialog
				PaperProps={{
					style: {
						minHeight: '40%',
						padding: "20px",
						maxHeight: '40%',
						width: "500px",
						borderRadius: "13px"
					}
				}}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", fontSize: "22px", margin: "0 auto", fontFamily: "Poppins" }} id="customized-dialog-title">
					No Customer Or Active Subscription!

				</DialogTitle>

				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<lord-icon
					src="https://cdn.lordicon.com/usownftb.json"
					trigger="in"
					colors="primary:#e83a30,secondary:#e83a30"
					style={{ width: "250px", height: "250px", margin: "0 auto" }}>
				</lord-icon>

				<DialogActions>
					<Button onClick={handleClose} style={{ backgroundColor: "white", color: "red", fontWeight: "bold", margin: "0 auto", fontSize: "20px" }}>Cancel</Button>



				</DialogActions>
			</BootstrapDialog>) : (
				<BootstrapDialog
					PaperProps={{
						style: {
							minHeight: '55%',
							maxHeight: '60%',
							width: "500px",
							borderRadius: "13px"
						}
					}}
					onClose={handleClose}
					aria-labelledby="customized-dialog-title"
					open={open}
				>
					<DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", margin: "0 auto", fontFamily: "Poppins" }} id="customized-dialog-title">
						Customer Information

					</DialogTitle>

					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent style={{ alignItems: "center", textAlign: "center", height: "600px" }} >
						
						<div style={{ border: "2px solid #dedede ", width: "auto", borderRadius: "16px", margin: "0 auto 5px auto", padding: "10px" }}>
{customerInfo[0]?.avatar ? (	 <img style={{ borderRadius:"10px", border:"1px solid #e4e4e4"}} src={customerInfo[0]?.avatar} />	):(					<img style={{ height: "150px", marginLeft: "0 auto", border: "1px solid #f5f5f5", borderRadius: "50%" }} src="./figure.png" />
)}
							<h2 style={{ margin: "0 auto", width: "auto", fontWeight: "bold" }}>  {customerInfo[0]?.customerID}</h2>
							<h2 style={{ margin: "0 auto", width: "auto", fontWeight: "bold" }}>  {customerInfo[0]?.Fullname}</h2>
							{(new Date(DateOFToday).valueOf() >= new Date(subInfo[0]?.EndDate).valueOf() || subInfo[0]?.RemainingSessions == "0") ? (
								<div style={{ display: "flex", justifyContent: "center" }}>
									<ErrorIcon sx={{ color: "red" }} />
									<h5 style={{ fontWeight: "bold", color: "red" }}>Subscription has ended</h5>

								</div>

							) : (RemainingDays <= 5 && new Date(DateOFToday).valueOf() < new Date(subInfo[0]?.EndDate).valueOf()) ? (
								<div style={{ display: "flex", justifyContent: "center" }}>
									<ErrorIcon sx={{ color: "red" }} />
									<h5 style={{ fontWeight: "bold", color: "red" }}>Subscription ends in {RemainingDays} Days</h5>
								</div>
							) : null}
							{shouldRenderBox ? (



								<Box alignItems="center" margin="auto" width="fit-content" textAlign="center" display="flex">
									<Checkbox
										checked={checked}
										onChange={handleChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
									<p style={{ fontWeight: "bold" }}>Session <DoDisturbOnIcon sx={{ color: "red" }} /> from next subscription</p>

								</Box>



							) : ("")}

						</div>



						<Container
							variant="stacked"

						>
							<ColumnLayout columns={3} variant="text-grid">


								<div>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<GoPackage size={20} style={{ color: "black", marginBottom: "0", marginRight: "7px", size: "12px" }} />   <h6 style={{ fontWeight: "bold", marginBottom: "0" }}>{subInfo[0]?.packageName} Package</h6>
									</div>

								</div>




								<div style={{ alignItems: "center", textAlign: "center" }}>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<DateRangeIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>End Date {subInfo[0]?.EndDate}  </label>
									</div>

								</div>






								<div style={{ alignItems: "center", textAlign: "center", margin: "0 auto" }}>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<FaRegistered size={23} sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", marginLeft: "0" }}>Registration Branch {subInfo[0]?.branch} </label>
									</div>

								</div>


								<div style={{ alignItems: "center", textAlign: "center" }}>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<DateRangeIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Last Check {subInfo[0]?.LastCheck} </label>
									</div>

								</div>





								<div style={{ alignItems: "center", textAlign: "center" }}>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<PaidIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Discount {subInfo[0]?.Discount} </label>
									</div>

								</div>

								<div style={{ alignItems: "center", textAlign: "center" }}>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<PaidIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Debt {subInfo[0]?.Remains} </label>
									</div>

								</div>






								<div>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<CancelPresentationIcon sx={{ color: "red", marginBottom: "0", marginRight: "5px", width: "20px", height: "20px" }} />   <h6 style={{ fontWeight: "bold", margin: "0" }}>Used {subInfo[0]?.UsedSessions} </h6>
									</div>

								</div>


								<div style={{ alignItems: "center", textAlign: "center" }}>
									<div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
										<CheckIcon sx={{ color: "green", marginBottom: "0", fontWeight: "bold", border: "2px solid green", height: "15px", width: "20px", marginRight: "5px" }} />   <h6 style={{ fontWeight: "bold", margin: "0" }}>Remains {subInfo[0]?.RemainingSessions} </h6>
									</div>

								</div>





							</ColumnLayout>
						</Container>











					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} style={{ backgroundColor: "white", color: "red", fontWeight: "bold" }}>Cancel</Button>

						<LoadingButton
							className="loading-button"
							style={{ width: "100px", marginTop: "0", borderRadius: "7px", height: "35px", backgroundColor: "#00ab41", fontSize: "14px" }}
							loading={ConfirmButtonLoading}
							loadingPosition="end"
							variant="contained"
							type="submit"
							onClick={handleCustomerCheckingIn}

						>
							<span style={{ color: "white" }}>Confirm</span>
						</LoadingButton>



					</DialogActions>
				</BootstrapDialog>
			)}

		</div>
	)
}


export default PreCheckInDialog