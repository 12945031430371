import { useEffect, useState, useRef } from "react"
import { DateRange } from 'react-date-range';
import { DatePicker } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import { Box, Button, Typography, Container, CircularProgress, TextField  } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';
import dayjs from "dayjs";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Input, MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';
import moment from 'moment';
function AddTenant() {
	const [categories, setCategories] = useState()
	const [loading, setLoading] = useState()
	const [selectedCategory, setSelectedCategory] = useState()
	const [selectedID, setSelectedID] = useState()
	const nameRef = useRef()
	const phoneRef = useRef()
	const addressrent = useRef()
	const paidvalueRef = useRef()
	const branch = localStorage.getItem("loggedBranch")


	

	const API = process.env.REACT_APP_API


	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		phone: Yup.string().min(11, 'Phone must be 11 digits').max(11, 'Phone must be 11 digits').required('Phone is required'),
		address: Yup.string().required('Address is required'),
		paidValue: Yup.number().required('Paid value is required').positive('Paid value must be a positive number'),
		category: Yup.string().required('Category is required'),
		startDate: Yup.date().nullable().required('Start date is required').typeError('Start date is invalid'),
		endDate: Yup.date().nullable().required('End date is required')
		  .typeError('End date is invalid'),
	  });

	// function onChange(date, dateString) {
	// 	setStartDate(dateString)
	// 	// setDate(dateString.split("/").reverse())

	// }

	// function onEndDatehange(date, dateString) {
	// 	setEndDate(dateString)
	// 	// setEnDate(dateString.split("/").reverse())


	// }


	useEffect(function () {
		async function fetchDate() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getRentCategories`)
				const data = await res.json()

				setCategories(data.categories)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchDate()
	}, [])


	function handleSelectCategory(x) {
		const arr = x.split(",")
		setSelectedCategory(arr[0])
		setSelectedID(arr[1])

		

	}


	function handleAddTenant(values) {
		fetch(`${API}/addTenant`, {
			method: 'POST',
			headers: {
			   'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			   'Name': values.name,
			   'Phone': values.phone,
			   "startDate": values.startDate,
			   "endDate": values.endDate,
			   "categoryID": values.category,
			   "price": values.paidValue,
			   "address":values.address,
			   "ID" :selectedID,
			   "branch": branch
			})
		}).then(response => response.json())
		.then(result=>{
			if (result.status == 200) {
				toast.success(`${result.Message}`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					});
			} else {
				toast.error(`${result.Message}`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
					});
			}
		})

	}
	const onSubmit = (values) => {
		console.log('Form values submitted:', values);
		handleAddTenant(values); // Call the parent handler
	  };

	if (loading) {
		return (
		  <Box
			sx={{
			  display: 'flex',
			  justifyContent: 'center',
			  alignItems: 'center',
			  height: '50vh', 
			}}
		  >
			<CircularProgress size={80} sx={{ color: '#0d056a' }} />
		  </Box>
		);

	}

	return (
		<>
		<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
<Formik
      initialValues={{
        name: '',
        phone: '',
        address: '',
        paidValue: '',
        category: '',
        startDate: null,
        endDate: null,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, setFieldValue, touched, errors }) => (
        <Form style={{ margin: '0 auto', border: '2px solid #e4e4e4', backgroundColor: 'white', height: '700px', width: '500px' }}>
          <div style={{ margin: '0 auto', width: '80%', display: 'flex',flexDirection:"row", justifyContent: 'center', alignItems: 'center', }}>
            {/* Start Date Picker */}
            <DatePicker
			
             value={values.startDate ? dayjs(values.startDate) : null}  // Convert string date to moment object
			 onChange={(date) => {
			   // Ensure it's a valid moment object before setting the field value
			   setFieldValue('startDate', date && date.isValid() ? date.format('YYYY-MM-DD') : null);
			 }}  // Convert moment to string (YYYY-MM-DD)
			 format="YYYY-MM-DD"
              placeholder="From"
              style={{ margin: '10px', width: '200px' }}
            />
            {touched.startDate && errors.startDate && <div style={{ color: 'red' }}>{errors.startDate}</div>}

            {/* End Date Picker */}
            <DatePicker
              value={values.endDate ? dayjs(values.endDate) : null} // Convert to moment if necessary
			  onChange={(date) => setFieldValue('endDate', date ? date.format('YYYY-MM-DD') : null)}  // Convert moment to string (YYYY-MM-DD)              format="YYYY-MM-DD"
              placeholder="To"
              style={{ margin: '10px', width: '200px' }}
            />
            {touched.endDate && errors.endDate && <div style={{ color: 'red' }}>{errors.endDate}</div>}
          </div>

          {/* Name Input */}
          <Field
            name="name"
            render={({ field }) => (
              <TextField
			  sx={{
				width: '100%',
				'& .MuiOutlinedInput-root': {
				  fontWeight: 'bold',
				  '& input': {
					backgroundColor: 'white',
					height: "20px",
					'&:focus': {
					  backgroundColor: '#e4e4e4',
					},
				  },
				  borderColor: 'gray',
				  '& fieldset': {
					borderColor: 'gray',
				  },
				  '&:hover fieldset': {
					borderColor: '#',
				  },
				  '&.Mui-focused fieldset': {
					borderColor: '#23395d',
					fontWeight: 'bold',
				  },
				},
				'& .MuiInputLabel-root': {
				  fontWeight: "bold",
				  color: 'gray',
				},
				'& .MuiInputLabel-root.Mui-focused': {
				  color: '#23395d',
				},
				'& .MuiInputBase-input': {
				  color: 'black',
				},
			  }}
                {...field}
                placeholder="Name"
                style={{ margin: '10px 0', width: '80%' }}
              />
            )}
          />
          {touched.name && errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}

          {/* Phone Input */}
          <Field
		  
            name="phone"
            render={({ field }) => (
              <TextField
                {...field}
				sx={{
					'& .MuiOutlinedInput-root': {
					  fontWeight: 'bold',
					  '& input': {
						backgroundColor: 'white',
						height: "20px",
						'&:focus': {
						  backgroundColor: '#e4e4e4',
						},
					  },
					  borderColor: 'gray',
					  '& fieldset': {
						borderColor: 'gray',
					  },
					  '&:hover fieldset': {
						borderColor: '#',
					  },
					  '&.Mui-focused fieldset': {
						borderColor: '#23395d',
						fontWeight: 'bold',
					  },
					},
					'& .MuiInputLabel-root': {
					  fontWeight: "bold",
					  color: 'gray',
					},
					'& .MuiInputLabel-root.Mui-focused': {
					  color: '#23395d',
					},
					'& .MuiInputBase-input': {
					  color: 'black',
					},
				  }}
                placeholder="Phone"
                style={{ margin: '10px 0', width: '80%' }}
              />
            )}
          />
          {touched.phone && errors.phone && <div style={{ color: 'red' }}>{errors.phone}</div>}

          {/* Address Input */}
          <Field
            name="address"
            render={({ field }) => (
              <TextField
			  sx={{
				width: '80%',
				'& .MuiOutlinedInput-root': {
				  fontWeight: 'bold',
				  '& input': {
					backgroundColor: 'white',
					height: "20px",
					'&:focus': {
					  backgroundColor: '#e4e4e4',
					},
				  },
				  borderColor: 'gray',
				  '& fieldset': {
					borderColor: 'gray',
				  },
				  '&:hover fieldset': {
					borderColor: '#',
				  },
				  '&.Mui-focused fieldset': {
					borderColor: '#23395d',
					fontWeight: 'bold',
				  },
				},
				'& .MuiInputLabel-root': {
				  fontWeight: "bold",
				  color: 'gray',
				},
				'& .MuiInputLabel-root.Mui-focused': {
				  color: '#23395d',
				},
				'& .MuiInputBase-input': {
				  color: 'black',
				},
			  }}
                {...field}
                placeholder="Address"
                style={{ margin: '10px 0', width: '80%' }}
              />
            )}
          />
          {touched.address && errors.address && <div style={{ color: 'red' }}>{errors.address}</div>}

          {/* Paid Value Input */}
          <Field
            name="paidValue"
            render={({ field }) => (
              <TextField
			  sx={{
				width: '100%',
				'& .MuiOutlinedInput-root': {
				  fontWeight: 'bold',
				  '& input': {
					backgroundColor: 'white',
					height: "20px",
					'&:focus': {
					  backgroundColor: '#e4e4e4',
					},
				  },
				  borderColor: 'gray',
				  '& fieldset': {
					borderColor: 'gray',
				  },
				  '&:hover fieldset': {
					borderColor: '#',
				  },
				  '&.Mui-focused fieldset': {
					borderColor: '#23395d',
					fontWeight: 'bold',
				  },
				},
				'& .MuiInputLabel-root': {
				  fontWeight: "bold",
				  color: 'gray',
				},
				'& .MuiInputLabel-root.Mui-focused': {
				  color: '#23395d',
				},
				'& .MuiInputBase-input': {
				  color: 'black',
				},
			  }}
                {...field}
                placeholder="Paid Value"
                style={{ margin: '10px 0', width: '80%' }}
              />
            )}
          />
          {touched.paidValue && errors.paidValue && <div style={{ color: 'red' }}>{errors.paidValue}</div>}

          {/* Category Selection */}
          <FormControl fullWidth style={{ margin: '10px 0',width:"80%" }} error={touched.category && Boolean(errors.category)}>
            <InputLabel>Category</InputLabel>
            <Select
              value={values.category}
              label="Category"
              onChange={(e) => setFieldValue('category', e.target.value)}
            >
              {categories?.filter(row=> {
				return (
					row.availability == "active"

				)
			  }).map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
            {touched.category && errors.category && <FormHelperText>{errors.category}</FormHelperText>}
          </FormControl>

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '20px', padding: '10px 20px', width:"60%", height:"40px", borderRadius:"10px" }}
          >
            Add 
          </Button>
        </Form>
      )}
    </Formik>
			

		</>
	) 
}

export default AddTenant