import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { DateRange } from 'react-date-range';

import "../../styles/dateRangePicker.css"
import { styled } from '@mui/material/styles';
import { Table } from 'antd';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DatePicker } from "antd";
import PaidIcon from '@mui/icons-material/Paid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Slider from '@mui/material/Slider';

import * as XLSX from 'xlsx'; 
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';



const API = process.env.REACT_APP_API

function ClinicFinance() {
	const [filtered, setFiltered] = useState("All")

	const [selectedBranch, setSelectedBranch] = useState("All")
	const [branchesLoading, setBranchesLoading] = useState()

	const [branches, setBranches] = useState()
	const [loading, setLoading] = useState()
	const [financeDage, setFinanceData] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [rednder, setRerender] = useState()
	const [trainer, setTrainer] = useState([])
	const [trainersLoading, setTrainersLoading] = useState()
	const [packagesLoading, setPackagesLoading] = useState(true)
	const [gender, setSelectedGender] = useState("All")
	const [Packages, setPackages] = useState([])
	const [trainerName, settrainerName] = useState("All")

	const [selectedPackage, setSelectedPackage] = useState("All")
	const [ageFrom, setAgeFrom] = useState("All")
	const [ageTo, setAgeTo] = useState("All")
	const [searched, setSearched] = useState(false)
	const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);


	const [tableParams, setTableParams] = useState({
		pagination: {
		  current: 1,
		  pageSize: 10,
		},
	  });
  
	  const fetchData = () => {
		setLoading(true);
		fetch(`${API}/getAllClinicFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}`)		.then((res) => res.json())
	
		  .then(( data ) => {
			setFinanceData(data.result)
			console.log(data)
			setLoading(false);
			setTableParams({
			  ...tableParams,
			  pagination: {
				...tableParams.pagination,
				total: 200,
				// 200 is mock data, you should read it from server
				// total: data.totalCount,
			  },
			});
		  });
	  };
	  useEffect(fetchData, []);
	  const handleTableChange = (pagination, filters, sorter) => {
		  setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		  });
		};
	  const columns = [
		   {
	  title: 'Customer ID',
	  dataIndex: 'CustomerID',
	  sorter: (a, b) => a.CustomerID - b.CustomerID,
	},
		  {
			title: 'Full name',
			dataIndex: 'Fullname',
			align:"center",
			sorter: {
				compare: (a, b) =>sorter( a.Fullname , b.Fullname),
			},
  
			
	  
		  },
  
		  {
			  title: 'Phone',
			  dataIndex: 'Phone',
			  align:"center"
  
		
			},
  
			{
			  title: 'Age',
			  dataIndex: 'customerAge',
			  sorter: (a, b) => a.customerAge - b.customerAge,
			  align:"center"
  
  
		
			},
  
  
			{
			  title: 'National ID',
			  dataIndex: 'NationalID',
			  align:"center"
  
		
			},
		  //   PayDate	Category	Dep	Trainer	Branch
			{
			  title: 'Paid Value',
			  dataIndex: 'PaidValue',
			  sorter: (a, b) => a.PaidValue - b.PaidValue,
			  align:"center"
  
		
			},
			{
			  title: 'Remianing Value',
			  dataIndex: 'Remaining',
			  sorter: (a, b) => a.Remaining - b.Remaining,
			  align:"center"
  
			},
			{
				title: 'Discount',
				dataIndex: 'discount',
				sorter: (a, b) => a.discount - b.discount,
				align:"center"
	
			  },
			{
			  title: 'Pay Date',
			  dataIndex: 'PayDate',
			  sorter: (a, b) => new Date(a.PayDate) - new Date(b.PayDate),			
			  align:"center"
  
		
			},
  
			{
			  title: 'Category',
			  dataIndex: 'Category',
			  align:"center"
  
			},
  
			
			
  
			{
				title: 'Branch',
				dataIndex: 'Branch',
				align:"center",
				sorter: {
					compare: (a, b) =>sorter( a.Branch , b.Branch),
				},
	
			  },
			
	  
		];



	function onChange(date, dateString) {
		console.log(dateString)
		setDate(dateString)
		console.log(startDate)
		setFiltered("filtered")

	}




	function onEndDatehange(date, dateString) {
		setEnDate(dateString)
		setFiltered("filtered")


	}






	// useEffect(function(){
	// 	async function fetchData() {
	// 		try {
	// 			setPackagesLoading(true)
	// 			const res = await fetch (`${API}/getClinicPack`)
	// 			const data  = await res.json()
	// 			setPackages(data.data)


	// 		} catch (e){
	// 			console.log(e)
	// 		} finally {
	// 			setPackagesLoading(false)

	// 		}
	// 	} fetchData()
	// }, [])

	useEffect(function () {
		async function fetchBranches() {
			try {
				setBranchesLoading(true)
				const res = await fetch(`${API}/branches`)
				const data = await res.json()
				setBranches(data.branches)

			} catch (e) {
				console.log(e)
			} finally {
				setBranchesLoading(false)
			}
		} fetchBranches()
	}, [])

	useEffect(function () {
		async function fetchDate() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getAllClinicFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}`)
				const data = await res.json()
				console.log(data.data)
				setFinanceData(data.data)

			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		} fetchDate()
	}, [rednder])


	useEffect(function () {
		async function getTrainers() {
			try {
				setTrainersLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				setTrainer(data.result)
			} catch (e) {
				console.log(e)
			} finally {
				setTrainersLoading(false)
			}
		} getTrainers()

	}, [])







	async function handleFilter() {
		try {
			setLoading(true)
			const res = await fetch(`${API}/getAllClinicFinance?startDate=${startDate}&endDate=${endDate}&Trainer=${trainerName}&branch=${selectedBranch}&ageFrom=${ageFrom}&ageTo=${ageTo}&gender=${gender}&package=${selectedPackage}`)
			const data = await res.json()
			setFinanceData(data.data)
			setSearched(true)

		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)


		}





	}



	const sumPaid = financeDage?.map(item => item.PaidValue).reduce((a, b) => a + b, 0)
	const sumRemaining = financeDage?.map(item => item.Remaining).reduce((a, b) => a + b, 0)

    const handleExport = () => {
        // Create a worksheet from the dataSource
        const ws = XLSX.utils.json_to_sheet(financeDage);
    
        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Add worksheet to workbook
    
        // Generate the Excel file and prompt download
        XLSX.writeFile(wb, 'TableData.xlsx');
      };


	{if (branchesLoading || trainersLoading )return <CircularProgress sx={{display:"flex", width:"fit-content", top:"50%",left:"50%", position:"absolute"}} />}
	return (


		<div style={{ backgroundColor: "#eaedf4", height: "100%", width: "100%" }} className="finance-page-container">


				<h3 style={{ margin: "0 auto", width: "300px" }}>Clinic Finance</h3>
				<Container
					sx={{
						padding: '20px',
						borderRadius: '8px',
						backgroundColor: "white",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						marginBottom: "20px",

					}}
				>

					<div style={{ borderRadius: "8px", width: "40%", margin: "20px auto", display: "flex", justifyContent: "space-between" }} className="datepicker-container">
						<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", textAlign: "center" }} placeholder='From' format={"YYYY-MM-DD"} onChange={onChange} />
						<DatePicker style={{ backgroundColor: "#eaedf4", height: "40px", border: "none", }} placeholder='To' format={"YYYY-MM-DD"} onChange={onEndDatehange} />

					</div>
				
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: '20px', marginTop: "40px" }}>
					<div style={{ display: "flex", margin: "0 auto", width: "auto", flexDirection: "row", alignItems: "flex-start", textAlign: "center", height: "80px" }}>
						{['Trainer', 'Branch', 'Gender'].map((placeholder, index) => (
							<div key={index} style={{ position: "relative", marginLeft: index > 0 ? "5px" : "0" }}>
								<label style={{
									position: "absolute",
									top: "-20px", // Position the label higher
									fontSize: "12px",
									color: "#777",
									padding: "0 5px",
									zIndex: 1,
								}}>
									{placeholder}
								</label>
								<select
									onChange={index === 0 ? (e) => settrainerName(e.target.value) :
										index === 1 ? (e) => setSelectedBranch(e.target.value) :
											index === 2 ? (e) => setSelectedGender(e.target.value) :
												(e) => setSelectedPackage(e.target.value)}
									style={{
										width: index === 0 ? "170px" : "150px",
										height: "40px",
										border: "none",
										textAlign: "center",
										alignItems: "center",
										marginTop: "10px", // Add space below the label
										backgroundColor: "#eaedf4",
										borderRadius: "8px",
									}}
									defaultValue=""
								>
									<option value="All" >All</option>
									{index === 0 && trainer?.map(t => (
										<option key={t.fullName} value={t.fullName}>{t.fullName}</option>
									))}
									{index === 1 && (
										<>
											{branches?.map(branch => (
												<option key={branch.name} value={branch.name}>{branch.name}</option>
											))}
										</>
									)}
									{index === 2 && (
										<>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</>
									)}
									{/* {index === 3 &&  (
											<>
												<option value="Package1">Package 1</option>
												<option value="Package2">Package 2</option>
											</>
										)} */}

									{index === 3 && 
									<>
									{/* {Packages.map(pack=>{
										return (
											<option key={pack.name} value={pack.packId}>{branch.name}</option>

										)

									})} */}
									</>
										// <><option key={"1"} value={"1"}>{"1"}</option><option key={"1"} value={"8"}>{"8"}</option><option key={"1"} value={"12"}>{"12"}</option></>
									}
								</select>
							</div>
						))}
					</div>
					<div style={{
						position: "relative", marginLeft: "5",
					}}>

						<div style={{ display: "flex", flexDirection: "row", marginBottom: "15px", }}>
							<div style={{ marginRight: "10px" }}>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",
							}}>
								Age from
							</label>

								<select onChange={(e) => setAgeFrom(e.target.value)} style={{
									width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
								}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
							<div>	<label style={{
								position: "absolute",
								top: "-20px", // Position the label higher
								fontSize: "12px",
								color: "#777",
								zIndex: 1,
								marginBottom: "5px",
								width: "100px",


							}}>
								Age to
							</label>

								<select onChange={(e) => setAgeTo(e.target.value)} style={{
									width: "80px", height: "40px", borderRadius: "12px", textAlign: "center", fontWeight: "Bold", backgroundColor: "#eaedf4", border: "none"
								}}>

									{Array.from({ length: 80 }, (_, index) => (
										<option value={index}>
											{index}
										</option>

									))}
								</select></div>
						</div>
					</div>

					<Button
						variant="contained"
						onClick={handleFilter}
						startIcon={<SearchIcon style={{ color: "black" }} />}
						sx={{
							":hover": {
								backgroundColor: '#eaedf4',

								boxShadow: 'none',

							},
							backgroundColor: "#d7d9e9",

							color: 'black',
							borderRadius: '6px',
							fontWeight: "bold",
							marginTop: "10px",
							margin: "auto",
							width: "150px",
							fontWeight: "Bold",
							boxShadow: 'none',
						}}
					>
						Search
					</Button>

					{searched ? (
    <lebel style={{marginTop:"10px"}}>
        <strong>{`${gender}`}</strong>
        {` Customers  `}
        <strong>{` between ${ageFrom} years and ${ageTo} years`}</strong>
        {` in `}
        <strong>{`${selectedBranch} branch` }</strong>
        {` within `}
        <strong>{`${startDate} and ${endDate}`}</strong>
        {` with  `}
        <strong>{`${trainerName} `}</strong>
    </lebel>
) : (
    ""
)}
				</div>

				</Container>



				<Container

					sx={{
						padding: '25px',
						borderRadius: '8px',
						marginBottom: "20px", // Set background color if needed
						height: "100px",
						alignItems: "center",
						textAlign: "center",
						justifyContent: "center",
						boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)', // Milder box shadow
						height: "180px",
						display: "flex",
						backgroundColor: "white"

					}}
				>

					<Paper
						elevation={0}

						sx={{
							padding: '10px',
							// background: 'linear-gradient(to right, #025d82, #0094d4)',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",

							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							color: "black",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",
						}}
					>


						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<AccountCircleIcon sx={{ fontSize: "30px" }} />

							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
								{financeDage?.length}
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							color: "black",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",

						}}
					>


						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon sx={{ fontSize: "30px" }} />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "30px" }}>
									Total
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{Number(sumPaid) + Number(sumRemaining)} EGP
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={10}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",
							marginRight: "13px",


							boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
						}}
					>
						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon sx={{ fontSize: "30px" }} />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "20px", fontSize: "30px" }}>
									Actual Paid
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{sumPaid} EGP
							</Typography>
						</Box>
					</Paper>

					<Paper
						elevation={0}
						sx={{
							padding: '10px',
							backgroundColor: "#edf0f7",
							border: "1px solid #e4e4e4",
							color: "black",
							borderRadius: '10px',
							display: 'block',
							textAlign: "center",
							alignItems: 'center',
							fontFamily: "poppins",
							width: "250px",
							height: "90px",
							display: "flex",
							justifyContent: "center",

							boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
						}}
					>
						<Box sx={{ display: "block", width: "100%", }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
								<PaidIcon sx={{ fontSize: "30px" }} />
								<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "23px" }}>
									Total Remaining
								</Typography>
							</Box>
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
								{sumRemaining} EGP
							</Typography>
						</Box>

					</Paper>
				</Container>




				<Container sx={{width:"fit-content", margin:"auto", marginBottom:"10px"
}}>
                <Button
                        variant="contained"
                        onClick={handleExport}
                        startIcon={<InsertDriveFileIcon style={{ color: "black" }} />}
                        sx={{
                            ":hover": {
                                backgroundColor: '#eaedf4',

                                boxShadow: 'none',

                            },
                            backgroundColor: "#d7d9e9",

                            color: 'black',
                            borderRadius: '6px',
                            fontWeight: "bold",
                            marginTop: "10px",
                            margin: "auto",
                            width: "200px",
                            fontWeight: "Bold",
                            boxShadow: 'none',
                        }}
                    >
                        Download Excel
                    </Button>
                </Container>




				{loading ? (<Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
				<CircularProgress />
			</Box>) : (<>
				<Table
      columns={columns}
    //   rowKey={(record) => record.login.uuid}
      dataSource={financeDage}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
    />






				</>


)




}





		</div>
	)

}

export default ClinicFinance