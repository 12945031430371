

import { useState, useEffect, useRef, useReducer } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "../../styles/customerProfile.css"
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from '@mui/material/List';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import moment from 'moment-timezone';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';

import Avatar from '@mui/material/Avatar';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';
import TextField from '@mui/material/TextField';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogContentText from '@mui/material/DialogContentText';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Tooltip from '@mui/material/Tooltip';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    Menu
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReturnToCustomer from './returnToCustomerModal';
import DeleteIcon from '@mui/icons-material/Delete';


// cancel subscription


import Divider from '@mui/joy/Divider';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const API = process.env.REACT_APP_API

function GymData({ isActive, setIsActive, age }) {
    const loggedBranch = localStorage.getItem("loggedBranch");
    const [customerData, setCustomerData] = useState([]);
    const [LoadingPackageData, setisLoadingPackageData] = useState()
    const [packages, setPackages] = useState([])
    const params = useParams()
    const [selectedPackage, setSelectedPackage] = useState([])
    const customerID = params.id
    const [loading, setLoading] = useState()
    const [subcriptionData, setSubcriptionData] = useState([])

	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))


    const [remaining, setRemaining] = useState()
    const [gymrender, rerender] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [PT, setPT] = useState([])
    const [PTLoading, setPTLoading] = useState()
    const [selectedPT, setSelectedPT] = useState()
    const [open, setOpen] = React.useState(false);
    const [buttonLoading, setButtonLoading] = useState()
    const [btnLoading, setBtnLoading] = useState()

    const [subID, setSubID] = useState()
    const [subStatus, setSubStatus] = useState()
    const [debtOpen, setDebtOpen] = React.useState(false);
    const [giftOpen, setGiftOpen] = React.useState(false);
    const [invtiationOpen, setInvitationOpen] = React.useState(false);
    const [subscriptionID, setSubscriptionID] = useState()
    const packageidUseRef = useRef()
    const trainerRef = useRef()

    const PayDebtValue = useRef()
    const [activationOpen, setActivationOpen] = React.useState(false);
    const [deleteSub, setDeleteSub] = React.useState(false);
    const [remainingSessions, setRemaininsSessions] = useState()
    const paybackvalue = useRef()
    const discountRef = useRef()
    const [archived, setArchived] = useState()
    const [increaseSub, setIncreaseSub] = useState(false)
    const cancelCause = useRef()
    const days_list = Array.from({ length: 30 }, (_, index) => index + 1);
    const sessionsRef = useRef()
    const daysRef = useRef()
    const invitedName = useRef()
    const invitedPhone = useRef()
    const invitedCustomerName = useRef()
    const invitedCustomerPhone = useRef()

    const employee = localStorage.getItem("User")

    const [Role, setRole] = useState()



    // return to customer Modal
    const [openReturnCustomer, setOpenRetrunCustomer] = useState(false);
	const handleOpenRetrunCustomer = () => setOpenRetrunCustomer(true);
	const handleCloseReturnCustomer = () => setOpenRetrunCustomer(false);
    const [remainingValue, setRemainingValue] = useState()



    // Purge the subscriptions confirmation modal
    const [openPurge, setOpenPurge] = useState(false);



    function handleSubPurge() {
        fetch(`${API}/purgeGymSub`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
  
                "subID": subID,
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {

                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    rerender(!gymrender)
                    setOpenPurge(false)


                } else {
                    toast.error(`Something went wrong`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

                    setOpenPurge(false)

                }
            })

    }

    function PurgeConfirmation() {
        return (
            <Modal open={openPurge} onClose={() => setOpenPurge(false)}>
            <ModalDialog variant="outlined" role="alertdialog">
              <DialogTitle sx={{padding:"0", fontWeight:"bold"}}>
                <WarningRoundedIcon />
                Confirmation
              </DialogTitle>
              <Divider />
              <DialogContent  sx={{padding:"0"}}>
                Are you sure you want to entirely delete the subscription?
              </DialogContent>
              <DialogActions>
               
                <Button variant="plain" color="neutral" onClick={() => setOpenPurge(false)}>
                  Cancel
                </Button>

                <Button variant="solid" sx={{
                     backgroundColor:'#C41C1C' ,
                     color:"white",
                     ":hover":{
                        backgroundColor:'#C41C1C' ,
                        color:"white",
                     }
                }} onClick={() => handleSubPurge()}>
                  Delete
                </Button>
              </DialogActions>
            </ModalDialog>
          </Modal>
        )
    }

    const validationSchema = Yup.object({
        selectedPackage: Yup.string().required('Package is required'),
        selectedPT: Yup.string().optional(),
        gymDiscount: Yup.number().nullable().min(0, 'Discount must be at least 0'),
        paid: Yup.number().nullable(),

    });

    const formik = useFormik({
        initialValues: {
            selectedPackage: '',
            selectedPT: '',
            gymDiscount: 0,
            paid: "", // Initialize as 0 for better handling
            graceDays: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            if (values.paid !== "" && !values.graceDays) {
                formik.setErrors({ graceDays: "Grace Days is required" });
                return; // Stop submission
            }

            handleClientSubscription(values);
            resetForm();


        }
    });
    useEffect(() => {
        const items = localStorage.getItem('Roles')
        if (items) {
            setRole(items);
        }
    }, []);



    const handleClickOpenActivation = () => {
        setActivationOpen(true);
    };

    const handleCloseActivation = () => {
        setActivationOpen(false);
    };


    const handleClickOpenArchive = () => {
        setArchived(true);
    };

    const handleCloseArchive = () => {
        setArchived(false);
    };




    const handleDebtOpen = () => {
        setDebtOpen(true);
    };
    const handleDebtClose = () => {
        setDebtOpen(false);
    };

    const handleGiftOpen = () => {
        setGiftOpen(true);
    };
    const handleGiftClose = () => {
        setGiftOpen(false);
    };



    const handleInvitationOpen = () => {
        setInvitationOpen(true);
    };
    const handleInvitationClose = () => {
        setInvitationOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleDeleteSubOpen = () => {
        setDeleteSub(true);
    };

    const handleDeleteSubClose = () => {
        setDeleteSub(false);
    };


    const handleIncreaseSubOpen = () => {
        setIncreaseSub(true);
    };

    const handleIncreaseSubClose = () => {
        setIncreaseSub(false);
    };


    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));


    function subStatusAndID(x, y) {
        setSubStatus(x)
        setSubID(y)
        handleClickOpenActivation()


    }



    function subStatusAndIDCancel(x, y) {
        setSubStatus(x)
        setSubID(y)


    }
    function subStatusAndIDArchive(x, y) {
        setSubStatus(x)
        setSubID(y)
        handleClickOpenArchive()


    }


    function handleClick() {
        PayGymDebt()
        setButtonLoading(true);
    }

    const upgrade = (
        <Paper style={{ marginLeft: "60%" }}>
            {/* <select required onChange={(e) => setSelectedPackage(e.target.value)} name="" id=""> */}
            <select onstyle={{ backgroundColor: "whitesmoke", color: "black", border: "none" }} required onChange={(e) => setSelectedPackage(e.target.value)} name="" id="">

                <option value="" selected disabled>Package</option>
                {packages.filter(p => {
                    return p.status === "active"
                }).map(pack => {
                    return (
                        <option value={pack.PackageID}> {pack.packageName} ({pack.Price} EGP) </option>


                    )
                })}
            </select>

        </Paper>
    );




    function GymDataComponent({ sub }) {
        return (

            <Card variant="outlined"sx={{
                position: 'relative', 
                transition: 'transform 0.3s, box-shadow 0.3s',
                padding: "20px", 
                paddingTop: "50px", // Extra space for the button
                width: "auto", 
                minWidth: "fit-content", 
                margin: "9px auto"
              }}>

                
{Role == "Admin" || Role == "admin" ? ( <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
    
    <IconButton 
    backgroundColor="white"
         aria-label="cancel" 
         sx={{
           color: 'grey', 
           
         }}
         onClick={() => {
           setSubID(sub.subscriptionid);
           setOpenPurge(true);
         }}    >
         <ClearIcon />
       </IconButton>
     </Box>) : ("")}


                <CardContent>
                    <div style={{ display: "flex", justifyContent: "space-evenly", }} fontWeight="md" textColor="success.plainColor">
                        <div className='package-name'>
                            <div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Package Name</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", alignItems: "center", textAlign: "center" }}>{sub?.packageName}</label></div>

                        </div>



                        <div className='package-status'>

                            <div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px", marginLeft: "20px" }}>Status</label></div>
                            {sub?.status == "inactive" ? (<div onClick={() => subStatusAndID("active", sub.subscriptionid)} style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "red", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {sub?.status}</label></div>
                            ) : (<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "green", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {sub?.status}</label></div>
                            )}
                        </div>


                        <div className='package-used'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}> <CancelPresentationIcon style={{ height: "20px", size: "30px", color: "darkred" }} />  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Used Sessions</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.UsedSessions}</p></div>

                        </div>


                        <div className='package-remaining'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>  <CheckBoxIcon style={{ height: "20px", size: "30px", color: "green" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Remaining Sessions</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.RemainingSessions}</p></div>

                        </div>

                        <div className='pack-endDate'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <CalendarMonthIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Package End Date</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.EndDate}</p></div>

                        </div>


                        {sub?.PersonalTrainer  ? (<div className='pack-endDate'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Personal Trainer</label></div>
                            <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.PersonalTrainer}</p></div>

                        </div>) : ("")}

                        {sub?.Inbody ? (<div className='pack-endDate'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <ListAltIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Inbody</label></div>
                            {sub?.Inbody == "Available" ? (<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold", color: "green" }}>{sub?.Inbody}</p></div>
                            ) : (<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold", color: "red" }}>{sub?.Inbody}</p></div>
                            )}
                        </div>) : ("")}

                        {sub?.ClassInvite ? (<div className='pack-endDate'>
                            <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <CardGiftcardIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Free class</label></div>
                            {sub?.ClassInvite == "Available" ? (
                                <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center", color: "green" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold", color: "green" }}>{sub?.ClassInvite}</p></div>
                            ) : (<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold", color: "red" }}>{sub?.ClassInvite}</p>
                                <p style={{ fontWeight: "bold" }}>({sub?.classUseDate})</p>
                            </div>
                            )}
                        </div>) : ("")}




                        {sub?.employee ? (
                            <div className='pack-endDate'>
                                <div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonAddIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Employee</label></div>
                                <div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.employee}</p></div>

                            </div>
                        ) : ("")}




                    </div>



                </CardContent> <br />


                {sub?.Remains != 0 ? (

                    <List sx={{ width: '100%', border: "1px solid #e4e4e4", bgcolor: 'background.paper', display: "flex", borderRadius: "10px", margin: "0 auto" }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <MoneyOffIcon sx={{ color: "black" }} />

                                </Avatar>
                            </ListItemAvatar>
                            <>
                                <label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`Paid ${sub?.Paid}  `}</label>

                            </>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <AttachMoneyIcon sx={{ color: "black" }} />
                                </Avatar>
                            </ListItemAvatar>
                            <label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`Remains ${Math.abs(sub?.Remains)}  `}</label>
                        </ListItem>


                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeIcon sx={{ color: "black" }} />
                                </Avatar>
                            </ListItemAvatar>
                            <label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`  ${sub?.RemainsGraceEndDate}  `}</label>

                        </ListItem>



                        <ListItem>



                        { 
                        sub?.Remains > 0 && 
                        (["admin", "receptionist"].includes(Role.toLowerCase())) ? (
                          <Button 
                          style={{width:"120px", border: "2px solid green",borderRadius:"7px", color: "green", fontWeight: "bold" }} 
                          onClick={() => { 
                              setSubscriptionID(sub.subscriptionid); 
                              handleDebtOpen(); 
                            }} 
                            variant="outlined" 
                            startIcon={<PaidIcon sx={{color:"green"}} />}
                          >
                            Pay
                          </Button>
                        ) : ("")
                      }

                            

                       { 
                        sub?.Remains < 0 && 
                        (["admin"].includes(Role.toLowerCase())) ? (
                          <Button 
                          style={{ width:"120px", border: "2px solid red", color: "red",borderRadius:"7px", fontWeight: "bold" }} 
                          onClick={() => { 
                              setSubscriptionID(sub.subscriptionid); 
                              setRemainingValue(sub.Remains)
                              handleOpenRetrunCustomer(); 
                            }} 
                            variant="outlined" 
                            startIcon={<PaidIcon sx={{color:"red"}} />}
                          >
                            Return
                          </Button>
                        ) : ("")
                      }
                      


                        </ListItem>




                    </List>) : ("")}


                {sub?.RemainingSessions == "0" || new Date(endDate) >=  new Date(sub?.EndDate)  ? (<Button onClick={() => subStatusAndIDArchive("archived", sub?.subscriptionid)} style={{ width: "100px", margin: "0 auto", border: "2px solid black", color: "black", fontWeight: "bold" }} variant="outlined" startIcon={<ArchiveIcon />}>Archive</Button>):("")
                }

                <div style={{ display: "flex", justifyContent: "space-around" }}>


                    {Role == "Admin" || Role == "admin" || Role == "Supervisor" ? (<Button style={{ whiteSpace: "nowrap", border: "1px solid darkblue", color: "red", fontWeight: "bold", fontSize: "10px" }} onClick={() => { setSubID(sub.subscriptionid); handleClickOpen() }} variant="outlined">
                        <EditIcon sx={{ color: "darkblue" }} />

                    </Button>) : ("")}


                    {Role == "Admin" || Role == "admin" ? (<Tooltip title={<Typography fontSize={15}>cancel sub</Typography>}>  <Button onClick={() => { setRemaininsSessions(sub.RemainingSessions); subStatusAndIDCancel("canceled", sub.subscriptionid); handleDeleteSubOpen() }} sx={{ border: "1px solid red" }} variant="outlined">
                        <CancelIcon sx={{ color: "Red" }} />

                    </Button> </Tooltip>) : ("")}


                    {Role == "Admin" || Role == "admin" || Role == "General Manager" ? (
                        <Tooltip title={<Typography fontSize={15}>increase sub</Typography>}>   <Button onClick={() => { setSubID(sub.subscriptionid); handleIncreaseSubOpen() }} sx={{ border: "1px solid green" }} variant="outlined">
                            <AddCircleOutlineIcon sx={{ color: "Green" }} />

                        </Button> </Tooltip>) : ("")}

                    {sub?.ClassInvite == "Available" ? (<Tooltip title={<Typography fontSize={15}>class invitation</Typography>}> <Button onClick={() => { setSubID(sub.subscriptionid); handleGiftOpen() }} sx={{ border: "1px solid #3a9bdc" }} variant="outlined">
                        <CardGiftcardIcon sx={{ color: "#3a9bdc" }} /> </Button></Tooltip>) : ("")}



                    {sub?.invitation == "Available" ? (<Tooltip title={<Typography fontSize={15}>invite</Typography>}>  <Button onClick={() => { setSubID(sub.subscriptionid); handleInvitationOpen() }} sx={{ border: "1px solid #003b46" }} variant="outlined"> <PersonAddIcon sx={{ color: "#003b46" }} />  </Button> </Tooltip>) : ("")}





                </div>
            </Card>



        )
    }







    useEffect(function () {
        async function fetchPTs() {
            try {
                setPTLoading(true)
                const res = await fetch(`${API}/getAllPTrainers`)
                const data = await res.json()
                setPT(data.result)
            } catch (e) {
                console.log(e)
            } finally {
                setPTLoading(false)
            }
        } fetchPTs()

    }, [])
    function HandleGymPackageChange(e) {
        e.preventDefault()
        fetch(`${API}/changeGymPackage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerID,
                'packageID': packageidUseRef.current.value,
                "subID": subID,
                "trainer": trainerRef.current.value,
                "discount":discountRef.current.value
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {

                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    rerender(!gymrender)
                    handleClose()


                } else {
                    toast.error(`Something went wrong`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }
            })

    }


    function invite(e) {
        e.preventDefault()
        fetch(`${API}/invite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerID,
                // 'invitedName': invitedName.current.value,
                // 'InvitedPhone': invitedPhone.current.value,
                "subID": subID
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

                    handleGiftClose()
                    rerender(!gymrender)

                }
            })

    }


    function handleCustomerInvitation(e) {
        e.preventDefault()
        fetch(`${API}/inviteCustomer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerID,
                'subID': subID,
                'invitedName': invitedCustomerName.current.value,
                'invitedPhone': invitedCustomerPhone.current.value,
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {

                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })


                    handleInvitationClose()
                    rerender(!gymrender)

                }


            })
    }

    useEffect(function () {
        async function fetchPackagesData() {
            setisLoadingPackageData(true)
            const res = await fetch(`${API}/getGymPackages`)
            const data = await res.json()
            setPackages(data.packages)
            setisLoadingPackageData(false)

        } fetchPackagesData()
    }, [gymrender])
    useEffect(function () {
        async function fetchSubscriptionData() {
            try {
                setLoading(true)
                const res = await fetch(`${API}/getClientBySub/${customerID}`)
                const data = await res.json()
                setSubcriptionData(data.subDetails)

            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        } fetchSubscriptionData()
    }, [gymrender])

    function handleClientSubscription(values) {
        setBtnLoading(true);

        fetch(`${API}/customer/subscribeCustomer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                PackageID: values.selectedPackage,
                CustomerID: customerID,
                Discount: values.gymDiscount,
                branch: loggedBranch,
                Paid: values.paid,
                Remains: remaining,
                RemainsGracePeriod: values.graceDays,
                CustomerName: customerData[0]?.Fullname,
                personalTrainer: values.selectedPT,
                NationalID: customerData[0]?.NationalID,
                phone: customerData[0]?.Phone,
                age: age,
                employee: employee,
            }),
        })
            .then(response => response.json())
            .then(result => {

                if (result.status === 200) {
                    // Directly update the subscription data state
                    setSubcriptionData(prevData => {
                        // Check if prevData is an array
                        if (Array.isArray(prevData)) {
                            return [...prevData, result.newSubscription[0]];
                        } else {
                            console.error('prevData is not an array:', prevData);
                            return [result.newSubscription[0]]; // fallback to create a new array
                        }
                    })

                    setTimeout(() => {
                        setBtnLoading(false)
                    }, 1500);
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });


                } else {
                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    setTimeout(() => {
                        setBtnLoading(false)
                    }, 1500);
                }
            })
            .catch(err => {
                console.error(err);
                setBtnLoading(false);
            });
    }


    function PayGymDebt(e) {
        e.preventDefault()
        setButtonLoading(true)
        fetch(`${API}/payGymDebt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerID,
                'debtValue': PayDebtValue.current.value,
                "subscriptionID": subscriptionID,
                "branch": loggedBranch
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    rerender(!gymrender)

              

                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

                } else {

                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

                }
                setButtonLoading(false)
                handleDebtClose()
            })

    }


    function updateGymSubStatus() {
        fetch(`${API}/updateGymSubStatus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'subscriptionID': subID,
                'status': subStatus,
                "customerID": customerID
            })
        }).then(response => response.json())
            .then(result => {
            
                if (result.status == "200") {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    rerender(!gymrender)
                    handleCloseActivation()

                } else if (result.status == "402") {
                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

                }
            })
    }



    function archiveSub() {
        fetch(`${API}/ArchiveGymSub`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'subscriptionID': subID,
                'status': subStatus,
                "customerID": customerID
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    rerender(!gymrender)
                    handleCloseArchive()

                } else if (result.status == "402") {
                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })

                }
            })
    }


    function cancelGymSub(e) {
        e.preventDefault()
        fetch(`${API}/cancelGymSub`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerID,
                'SubID': subID,
                "paybackvalue": paybackvalue.current.value,
                "cause": cancelCause.current.value,
                "branch": loggedBranch
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {

                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                    rerender(!gymrender)
                    handleDeleteSubClose()


                } else {
                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }
            })
    }




    function handleIncreaseGymQuota(e) {
        e.preventDefault()
        fetch(`${API}/increaseGymQuota`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'customerID': customerID,
                'sessions': sessionsRef.current.value,
                'days': daysRef.current.value,
                "subID": subID
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: false
                    })
                    handleIncreaseSubClose()

                    rerender(!gymrender)




                } else if (result.status == 404) {
                    toast.error(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: false
                    })


                }

            })

    }

    {
        if (loading || PTLoading || LoadingPackageData) return <Box minHeight="1000px">
            <CircularProgress sx={{ margin: "0 auto", width: "fit-content", display: "flex" }} />
        </Box>
    }

    return (

        <Box style={{ margin: "0 auto", width: "auto", height: "auto", padding: "30px" }}>
<PurgeConfirmation />

<ReturnToCustomer toast={toast} rerender={rerender} gymrender={gymrender} loggedBranch={loggedBranch} customerID={customerID} subscriptionID={subscriptionID} remainingValue={remainingValue} openReturnCustomer={openReturnCustomer}  handleCloseReturnCustomer ={handleCloseReturnCustomer} setOpenRetrunCustomer={setOpenRetrunCustomer} handleOpenRetrunCustomer={handleOpenRetrunCustomer}/>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                
            >
                <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", fontFamily: "Poppins", width: "fit-content", margin: "auto" }} id="customized-dialog-title">
                    Change package
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent style={{ width: "100%", height: "100%", padding: "40px" }}
                    dividers>
                    {/* <select required onChange={(e) => setSelectedPackage(e.target.value)} name="" id=""> */}
                    <form  onSubmit={(e)=>HandleGymPackageChange(e)} style={{ display: "block",marginTop:"0",fontFamily:"poppins",paddingTop:"0", flexDirection: "column", backgroundColor:"white", width:"250px", height:"250px", margin:"auto" }}>
                        <select  style={{ width:"90%", minWidth: "90%", margin: "auto", textAlign: "center", backgroundColor: "whitesmoke", color: "black", border: "1px solid #e4e4e4", marginBottom: "6px", backgroundColor: "whitesmoke" }}  ref={packageidUseRef} name="" id="">

                            <option value="" selected disabled>Package</option>
                            {packages?.filter(pack=>{
                                return pack.status == "active"
                            }).map(pack => {
                                return (
                                    <option value={pack.PackageID}> {pack.packageName} ({pack.Price} EGP/{pack.sessionsCount} Sessions) </option>


                                )
                            })}
                        </select> <br />

                        <select
                            style={{ width:"90%", minWidth: "90%", textAlign: "center",fontFamily:"poppins",backgroundColor: "whitesmoke", color: "black", border: "none", border: "1px solid #e4e4e4", marginBottom: "15px", backgroundColor: "whitesmoke" }}
                            name="selectedPT"
                            ref={trainerRef}
                            
                            // value={formik.values.selectedPT}

                            label="Personal Trainer"
                        >
                            <option value="" selected disabled>Personal Trainer? </option>
                            {PT?.map(pt => (
                                <option value={pt.fullName}>
                                    {pt.fullName}
                                </option>
                            ))}
                        </select>
                        
                        <input ref={discountRef} defaultValue="0" required type='text' placeholder='Discount Value' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} /> <br />


                        <Button type="submit" style={{margin:"auto",marginTop:"15px", backgroundColor: "#006241", color: "white", fontWeight: "bold", width: "250px", borderRadius: "8px", height: "40px" }} autoFocus >
                        confirm
                    </Button>
                    </form>
                </DialogContent>
              
            </BootstrapDialog>


            <BootstrapDialog
                sx={{ padding: "50px" }}
                onClose={handleGiftClose}
                aria-labelledby="customized-dialog-title"
                open={giftOpen}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleGiftClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <h4 style={{ margin: "10px auto", fontWeight: "bold" }}>Free Class</h4>
                <lord-icon
                    src="https://cdn.lordicon.com/ofwxettw.json"
                    trigger="in"
                    delay="200"
                    state="in-reveal"
                    colors="primary:#107c91,secondary:#107c91"
                    style={{ width: "150px", height: "150px", margin: "0 auto" }}>
                </lord-icon>
                <DialogContent sx={{ margin: "0 auto" }}>
                    <form onSubmit={(e) => invite(e)} style={{ height: "120px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0" }}>
                        {/* <input ref={invitedName} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Name' /> <br />
                                <input ref={invitedPhone} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Phone' /> <br /> */}
                        <Button type='submit' style={{ backgroundColor: "black", color: "white", fontWeight: "bold", margin: "0 auto", borderRadius: "10px" }} autoFocus onClick={handleClose}>
                            Use
                        </Button>
                    </form>


                </DialogContent>

            </BootstrapDialog>







            <BootstrapDialog
                sx={{ padding: "50px" }}
                onClose={handleInvitationClose}
                aria-labelledby="customized-dialog-title"
                open={invtiationOpen}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleInvitationClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <h4 style={{ margin: "10px auto", fontWeight: "bold" }}>Invitation</h4>
                <img style={{ width: "200px", margin: "0 auto" }} src="/invite.png" />
                <DialogContent sx={{ margin: "0 auto" }}>
                    <form onSubmit={(e) => handleCustomerInvitation(e)} style={{ height: "120px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0", border: "1px solid #e4e4e4", padding: "5px" }}>
                        <input ref={invitedCustomerName} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Name' /> <br />
                        <input ref={invitedCustomerPhone} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Phone' /> <br />
                        <Button type='submit' style={{ backgroundColor: "black", color: "white", fontWeight: "bold", margin: "0 auto", borderRadius: "10px" }} autoFocus onClick={handleClose}>
                            Confirm
                        </Button>
                    </form>


                </DialogContent>

            </BootstrapDialog>




            {/* Delete Dialog */}

            <BootstrapDialog
                onClose={handleDeleteSubOpen}
                open={deleteSub}
                aria-labelledby="customized-dialog-title"



            >
                <IconButton
                    aria-label="close"
                    onClick={handleDeleteSubClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>


                <DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
                    <h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Cancel Subscription?</h5>
                    <h8 style={{ marginLeft: "15px" }}> {remainingSessions} Remaining sessions</h8>


                </DialogContent>

                <DialogContent sx={{ margin: "0 auto" }}>
                    <img style={{ width: "150px", margin: "0 auto" }} src='/cancelsub.png' />
                </DialogContent>

                <DialogContent sx={{ margin: "0 auto" }}>
                    <form onSubmit={(e) => cancelGymSub(e)} style={{ height: "150px", width: "200px", backgroundColor: "white", padding: "10px", marginLeft: "0", border: "1px solid #e4e4e4", width: "auto" }}>
                        <input ref={paybackvalue} required type='text' placeholder='Payback Value' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} /> <br />
                        <select ref={cancelCause} style={{ marginBottom: "7px", width: "200px", border: "1px solid #e4e4e4", alignItems: "center", textAlign: "center" }}>
                            <option style={{ alignItems: "center", textAlign: "center" }} disabled selected>Cause</option>
                            <option style={{ alignItems: "center", textAlign: "center" }} value="Retrun">Retrun</option>
                            <option style={{ alignItems: "center", textAlign: "center" }} value="Wrong">Wrong</option>

                        </select>
                        <button type='submit' style={{ backgroundColor: "black", color: "white", margin: "0 auto", marginTop: "20px" }} autoFocus >
                            Confirm
                        </button>
                    </form>
                </DialogContent>





            </BootstrapDialog>




            {/* Increase Quota */}



            <BootstrapDialog
                onClose={handleIncreaseSubClose}
                open={increaseSub}
                aria-labelledby="customized-dialog-title"



            >
                <IconButton
                    aria-label="close"
                    onClick={handleIncreaseSubClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>


                <DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
                    <h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Increase Subscription</h5>


                </DialogContent>

                <DialogContent sx={{ margin: "0 auto", height: "190px" }}>
                    <img style={{ width: "150px", margin: "0 auto", marginBottom: "0" }} src='/add.png' />
                </DialogContent>

                <DialogContent sx={{ margin: "0 auto", marginTop: "0" }}>
                    <form onSubmit={(e) => handleIncreaseGymQuota(e)} style={{ height: "150px", width: "200px", backgroundColor: "white", padding: "5px", marginLeft: "0", marginBottom: "10px" }}>
                        <input ref={sessionsRef} required type='text' placeholder='Num of sessions' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />
                        <input ref={daysRef} required type='text' placeholder='Days' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />

                        <button type='submit' style={{ backgroundColor: "black", color: "white", margin: "0 auto" }} autoFocus >
                            Confirm
                        </button>
                    </form>
                </DialogContent>





            </BootstrapDialog>


            {/* end of increase quota  */}




            <Dialog
                open={activationOpen}

                onClose={handleCloseActivation}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseActivation}>cancel</Button>
                    <Button onClick={updateGymSubStatus}>Activate</Button>
                </DialogActions>
            </Dialog>




            <Dialog
                open={archived}

                onClose={handleCloseArchive}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseArchive}>cancel</Button>
                    <Button onClick={archiveSub}>Archive</Button>
                </DialogActions>
            </Dialog>



            <Dialog
                maxWidth="sm"
                open={debtOpen}
                onClose={handleDebtClose}
            >
                <DialogContent sx={{ margin: "0 auto" }}>
                    <img style={{ width: "200px", margin: "0 auto", height: "200px" }} src="/pay.png" />
                </DialogContent>
                <DialogContent>
                    <form onSubmit={(e) => PayGymDebt(e)} style={{ height: "100px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0" }}>
                        <input required ref={PayDebtValue} style={{ border: "1px solid grey", alignItems: "cemter", textAlign: "center", borderRadius: "7px", marginTop: "4px" }} type='text' placeholder='Pay value' />
                        <div style={{ display: "flex", margin: "0 auto", justifyContent: "space-evenly" }}>
                            <LoadingButton
                                style={{ backgroundColor: "#03c03c",color:"White", marginTop: "0",  fontWeight: "bold" }}
                                loading={buttonLoading}
                                loadingPosition="start"
                                type='submit'
                                startIcon={<AttachMoneyIcon sx={{color:"white"}} />}

                            >
                                Pay
                            </LoadingButton>
                        </div>


                    </form>
                </DialogContent>
                <DialogActions>


                </DialogActions>
            </Dialog>



            {subcriptionData?.map(sub => {
                return (<GymDataComponent sub={sub} />)

            })}

            <div >
                <form
                    style={{ width: '340px', height: "auto", margin: '0 auto', padding: "20px", backgroundColor: "white", border: "1px solid #e4e4e4" }}
                    onSubmit={formik.handleSubmit}
                >
                    <h5>Select Package</h5>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>Package</InputLabel>
                        <Select
                            name="selectedPackage"
                            value={formik.values.selectedPackage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label="Package"
                            sx={{
                                color: 'black',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black',
                                        borderWidth: 2,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'black',
                                        borderWidth: 2,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                        borderWidth: 2,
                                    },
                                },
                            }}
                        >
                            <MenuItem value="" disabled>Package</MenuItem>
                            {packages.filter(p => {
                                return p.status == "active"
                            }).map(pack => (
                                <MenuItem key={pack.PackageID} value={pack.PackageID}>
                                    {pack.packageName} ({pack.Price} EGP / {pack.sessionsCount}) {pack.Inbody ? `+ ${pack.Inbody}` : ""} {pack.ClassInvite ? ` + ${pack.ClassInvite}` : ""}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.selectedPackage && formik.errors.selectedPackage && (
                            <div style={{ color: 'red' }}>{formik.errors.selectedPackage}</div>
                        )}
                    </FormControl>

                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>Personal Trainer</InputLabel>
                        <Select
                            name="selectedPT"
                            value={formik.values.selectedPT}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            label="Personal Trainer"
                        >
                            <MenuItem value="" disabled>Personal Trainer</MenuItem>
                            {PT?.map(pt => (
                                <MenuItem key={pt.fullName} value={pt.fullName}>
                                    {pt.fullName}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.selectedPT && formik.errors.selectedPT && (
                            <div style={{ color: 'red' }}>{formik.errors.selectedPT}</div>
                        )}
                    </FormControl>

                    <TextField
                        name="gymDiscount"
                        type="number"
                        value={formik.values.gymDiscount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black',
                                    borderWidth: 2,
                                },
                                '&:hover fieldset': {
                                    borderColor: 'black',
                                    borderWidth: 2,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black',
                                    borderWidth: 2,
                                },
                            },
                        }}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        placeholder='Discount'
                    />
                    {formik.touched.gymDiscount && formik.errors.gymDiscount && (
                        <div style={{ color: 'red' }}>{formik.errors.gymDiscount}</div>
                    )}

                    <Box sx={{ height: 180, margin: "0 auto" }}>
                        <FormControlLabel
                            control={
                                <Button
                                    style={{ border: "1px solid #e4e4e4", margin: "0 auto", marginBottom: "10px", fontWeight: "bold", width: "200px", height: "30px" }}
                                    endIcon={<AttachMoneyIcon style={{ color: "red" }} />}
                                    onClick={() => setChecked((prev) => !prev)}
                                    variant="outlined"
                                    color="error"
                                >
                                    Debt
                                </Button>
                            }
                        />
                        <Box sx={{ display: 'flex' }}>
                            <Fade in={checked}>
                                <Paper style={{ padding: '20px', width: '340px', margin: '0 auto' }}>
                                    <TextField
                                        name="paid"
                                        type="number"
                                        placeholder="Paid"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.paid}
                                        fullWidth
                                        margin="normal"
                                        error={formik.touched.paid && Boolean(formik.errors.paid)}
                                        helperText={formik.touched.paid && formik.errors.paid}
                                    />
                                    
                                    {formik.values.paid !== ""  && (
    <Select
        name="graceDays"
        value={formik.values.graceDays}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        displayEmpty
        fullWidth
        margin="normal"
        error={formik.touched.graceDays && Boolean(formik.errors.graceDays)}
    >
        <MenuItem value="" disabled>Grace period (days)</MenuItem>
        {days_list.map((d) => (
            <MenuItem key={d} value={d}>{d}</MenuItem>
        ))}
    </Select>
)}

{formik.touched.graceDays && formik.errors.graceDays && (
    <div style={{ color: 'red' }}>{formik.errors.graceDays}</div>
)}


                                </Paper>
                            </Fade>
                        </Box>
                    </Box>

                    <LoadingButton
                        sx={{
                            height: "40px",
                            fontWeight: "bold",
                            borderRadius: "12px",
                            backgroundColor: "#152238",
                            width: "250px",
                            color: "white",
                            margin: "auto",
                            ":hover": {
                                backgroundColor: "#152238",
                            },
                        }}
                        type="submit" // Change to submit
                        loading={btnLoading}
                        variant="contained"
                    >
                        Submit
                    </LoadingButton>
                </form>
            </div>




        </Box>






    )
}

export default GymData