import { useState } from "react"
import "../../styles/classStyles/class.css"
import { useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import classNames from "classnames";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import ClassNav from "../../components/classNav";
import { ToastContainer, toast } from 'react-toastify';
import { IoCloseOutline } from "react-icons/io5";
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { TimePicker } from "antd";
import dayjs from 'dayjs';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Drawer from '@mui/material/Drawer';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Checkbox,
} from '@mui/material';

import DateRangeIcon from '@mui/icons-material/DateRange';
import PaidIcon from '@mui/icons-material/Paid';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { GoPackage } from "react-icons/go";
import { FaRegistered } from "react-icons/fa";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import ErrorIcon from '@mui/icons-material/Error';

import { Menu } from "@mui/material";

import { IoFilter } from "react-icons/io5";

import { ColumnLayout, Container, Header, Table } from '@cloudscape-design/components';

import {
    Table as MUITABLE,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

function ClassesPage() {
    const [classesData, setClassesData] = useState([])
    const [loading, setLoading] = useState()
    const [classesLoading, setClassesLoading] = useState()
    const DateOFToday = moment.tz('Africa/Cairo').format('YYYY-MM-DD')
    const [registeringCustomerID, setRegisteringCustomerID] = useState()

    const [all, setAll] = useState("All");
    const days = { "1": "monday", "2": "tuesday", "3": "wednesday", "4": "thursday", "5": "friday", "6": "saturday", "0": "sunday", "7": "sunday" }
    const [classFamily, setClassID] = useState()

    const [open, setOpen] = React.useState(false);
    const [trainer, setTrainer] = useState()
    const [trainersLoading, setTrainersLoading] = useState()
    const [selectedOption, setSelectedOption] = useState(null);
    const [renderValue, setRenderValue] = useState(false)
    const [rerender, setRender] = useState(renderValue)
    const [items, setItems] = useState([]);
    const [sideOpen, setSideOpen] = React.useState(false);
    const [changedClassTrainer, setChangedClassTrainer] = useState()
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [checkInDesc, setCheckInDesc] = useState()
    const [checkInFamily, setCheckInFamily] = useState()
    const Branch = localStorage.getItem("loggedBranch")
    const [customerInfo, setCustomerInfo] = useState("")
    const [subInfo, setSubInfo] = useState("")
    const [branches, setBranches] = useState()
    const [selectedBranch, setSelectedBranch] = useState(Branch)

    const [InfoOpen, setInfoOpen] = React.useState(false);
    const animatedComponents = makeAnimated();
    const [timesID, setTimesID] = useState()
    const [className, setClassName] = useState()
    const [classTrainer, setClassTrainer] = useState()

    const Role = localStorage.getItem("Roles")
    const RemainingDays = Math.abs(new Date(subInfo[0]?.EndDate) - new Date(DateOFToday)) / (1000 * 60 * 60 * 24)
    const [inActiveCount, setInActiveCount] = useState("")


    const isExpired = new Date(DateOFToday).valueOf() > new Date(subInfo[0]?.EndDate).valueOf();
    const hasGraceSession = subInfo[0]?.grace_session === 1;
    const noRemainingSessions = subInfo[0]?.RemainingSessions === 0;
    const isInactiveCountZero = inActiveCount === 0;
    const shouldRenderBox = (isExpired && hasGraceSession && isInactiveCountZero) ||
        (noRemainingSessions && hasGraceSession && isInactiveCountZero)

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    // filter dropMenu
    const [anchorEl, setAnchorEl] = useState(null);
    const openFilter = Boolean(anchorEl);
    const [filter, setFilter] = useState("All")

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option) => {
        setFilter(option)
        handleCloseFilter();
    };




    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClickOpenDelete = () => {
        setDeleteOpen(true);
    };

    const handleCloseDelete = () => {
        setDeleteOpen(false);
    };


    const classTimes = []
    const date = new Date()
    const todayNumber = (date.getDay())
    const todayName = days[todayNumber]
    const format = 'HH:mm';
    const selectedDays = []

    selectedOption?.map(day => {
        selectedDays.push(day.value)
    });

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    const weekDays = [
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' }


    ]
    function Delete(clas) {
        setTimesID(clas.timeID)
        setClassID(clas.id)


        handleClickOpenDelete()
    }

    const handleClickOpenInfo = () => {
        setInfoOpen(true);
    };

    const handleCloseInfo = () => {
        setInfoOpen(false);
    };



    const onChange = (time, timeString) => {
        classTimes.push(timeString);
    };



    function toggleDrawer(newOpen) {
        setSideOpen(newOpen);
    };




    const DrawerList = (

        <>
            <IoCloseOutline size={30} style={{ cursor: "pointer" }} onClick={() => toggleDrawer(false)} />
            <h4 style={{ margin: "0 auto", fontWeight: "bold" }}>{checkInDesc} {checkInFamily}</h4>
            <form onSubmit={(e) => getCustomerInfo(e)} className='add-customer-toAcademy-from'>

                <input autoFocus style={{ height: "40px", width: "200px" }} required onChange={(e) => setRegisteringCustomerID(e.target.value)} placeholder="Customer ID" type="text" /> <br />
                {/* <button onClick={(e) => handleClassCheckIn(e)}>Check in</button> */}
                <LoadingButton
                    style={{ width: "150px" }}
                    loading={buttonLoading}
                    loadingPosition="end"
                    variant="contained"
                    type="submit"
                >
                    <span style={{ color: "white" }}>Check in</span>
                </LoadingButton>

            </form>

        </>


    )








    useEffect(() => {
        const items = localStorage.getItem('Roles')
        if (items) {
            setItems(items);
        }
    }, []);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleBtns = (e) => {
        let word = e.target.value;
        setAll(word);
    };



    function checkin(x, y, z, t) {
        toggleDrawer(true)
        setClassID(x)
        setCheckInDesc(y)
        setCheckInFamily(z)
        setClassTrainer(t)
    }


    function Modify(c) {
        setClassID(c.id)
        setTimesID(c.timeID)
        setClassName(c.Description)


        handleClickOpen()
    }


    useEffect(function () {
        async function getBranches() {
            try {
                setClassesLoading(true)
                const res = await fetch(`${API}/branches`)
                const data = await res.json()
                setBranches(data.branches)
                console.log(branches)
            } catch (e) {
                console.log(e)
            } finally {
                setClassesLoading(false)
            }

        } getBranches()
    }, [])

    async function getCustomerInfo(e) {
        e.preventDefault()
        try {
            const res = await fetch(`${API}/ClassPreSub/${registeringCustomerID}/${checkInFamily}`)
            const data = await res.json()
            setCustomerInfo(data.customerInfo)
            setSubInfo(data.subInfo)
            setInActiveCount(data.inactiveNum[0].count)

        } catch (e) {
            console.log(e)
        } finally {
            handleClickOpenInfo()


        }
    }

    useEffect(function () {
        async function getTrainers() {
            try {
                setTrainersLoading(true)
                const res = await fetch(`${API}/getAllPTrainers`)
                const data = await res.json()
                setTrainer(data.result)

            } catch (e) {
                console.log(e)
            } finally {
                setTrainersLoading(false)
            }
        } getTrainers()

    }, [])

    useEffect(() => {
        if (all === "All") {
            setClassesData(classesData);
        } else {
            const filtered = classesData.filter((c) => {
                return (
                    c.Days.toLowerCase().includes(todayName.toLowerCase())
                );
            });
            setClassesData(filtered);

        }
    }, [all]);




    function handleClassCheckIn(e) {
        setButtonLoading(true)
        e.preventDefault(e)
        fetch(`${API}/customerClassCheckIn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "classID": classFamily,
                "CustomerID": registeringCustomerID,
                "Branch": Branch,
                "checked": checked,
                "trainer": classTrainer
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    setButtonLoading(false)
                    handleCloseInfo()
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: 5000,
                    }


                    );
                    setChecked(false)


                } else {

                    setButtonLoading(false)
                    handleCloseInfo()
                    toast.error(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        autoClose: false,
                    });
                    setChecked(false)


                }


            })

    }



    const API = process.env.REACT_APP_API



    useEffect(function () {
        async function fetchData() {
            try {
                setLoading(true)
                const res = await fetch(`${API}/classesFetch?branch=${selectedBranch}&filter=${filter}`)
                const data = await res.json()
                setClassesData(data.classes)

                console.log(data)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }

        } fetchData()
    }, [rerender, selectedBranch, filter])


    function changeTrainer(e) {
        e.preventDefault()
        fetch(`${API}/assignClassTrainer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'classID': classFamily,
                "timesID": timesID,
                "branch": selectedBranch,
                'trainerName': changedClassTrainer,
                "time": classTimes,
                "days": selectedDays,
                "className": className
            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",

                    });
                    setRender(!rerender)

                    handleClose()
                }
            })



    }


    function DeleteTime(e) {
        e.preventDefault()
        fetch(`${API}/deleteClass`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({

                "timeID": timesID,
                "classID": classFamily


            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == "200") {
                    toast.success(result.Message, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                handleCloseDelete()
                setRender(!rerender)
            })


    }


    return (

        <div className="classespage">
            <Dialog
                open={deleteOpen}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ fontWeight: "bold" }} id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancel</Button>
                    <Button onClick={DeleteTime} >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {customerInfo.length == 0 || subInfo == 0 ? (<BootstrapDialog
                PaperProps={{
                    style: {
                        minHeight: '40%',
                        padding: "20px",
                        maxHeight: '40%',
                        width: "500px",
                        borderRadius: "13px"
                    }
                }}
                onClose={handleCloseInfo}
                aria-labelledby="customized-dialog-title"
                open={InfoOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", fontSize: "22px", margin: "0 auto", fontFamily: "Poppins" }} id="customized-dialog-title">
                    No Customer Or Active Subscription!

                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleCloseInfo}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <lord-icon
                    src="https://cdn.lordicon.com/usownftb.json"
                    trigger="in"
                    colors="primary:#e83a30,secondary:#e83a30"
                    style={{ width: "250px", height: "250px", margin: "0 auto" }}>
                </lord-icon>

                <DialogActions>
                    <Button onClick={handleCloseInfo} style={{ backgroundColor: "white", color: "red", fontWeight: "bold", margin: "0 auto", fontSize: "20px" }}>Cancel</Button>



                </DialogActions>
            </BootstrapDialog>) : (
                <BootstrapDialog
                    PaperProps={{
                        style: {
                            minHeight: '60%',
                            maxHeight: '70%',
                            width: "500px",
                            borderRadius: "13px"
                        }
                    }}
                    onClose={handleCloseInfo}
                    aria-labelledby="customized-dialog-title"
                    open={InfoOpen}
                >
                    <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", margin: "0 auto", fontFamily: "Poppins" }} id="customized-dialog-title">
                        Customer Information

                    </DialogTitle>

                    <IconButton
                        aria-label="close"
                        onClick={handleCloseInfo}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent style={{ alignItems: "center", textAlign: "center" }} >
                        <div style={{ width: "50%", borderRadius: "16px", margin: "0 auto 5px auto" }}>


                            <div style={{ width: "auto", borderRadius: "16px", margin: "0 auto 5px auto", padding: "10px" }}>
                                {customerInfo[0]?.avatar ? (<img style={{ borderRadius: "10px", border: "1px solid #e4e4e4" }} src={customerInfo[0]?.avatar} />) : (<img style={{ height: "150px", marginLeft: "0 auto", border: "1px solid #f5f5f5", borderRadius: "50%" }} src="./figure.png" />
                                )}
                            </div>
                            <h2 style={{ margin: "0 auto", width: "auto", fontWeight: "bold" }}>  {customerInfo[0]?.customerID}</h2>
                            <h2 style={{ margin: "0 auto", width: "auto", fontWeight: "bold", fontSize: "20px" }}>  {customerInfo[0]?.Fullname}</h2>
                        </div>
                        {(new Date(DateOFToday).valueOf() >= new Date(subInfo[0]?.EndDate).valueOf() || subInfo[0]?.RemainingSessions == "0") ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <ErrorIcon sx={{ color: "red" }} />
                                <h5 style={{ fontWeight: "bold", color: "red" }}>Subscription has ended</h5>
                            </div>
                        ) : (RemainingDays <= 5 && new Date(DateOFToday).valueOf() < new Date(subInfo[0]?.EndDate).valueOf()) ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <ErrorIcon sx={{ color: "red" }} />
                                <h5 style={{ fontWeight: "bold", color: "red" }}>Subscription ends in {RemainingDays} Days</h5>
                            </div>
                        ) : null}


                        {shouldRenderBox ? (



                            <Box alignItems="center" margin="auto" width="fit-content" textAlign="center" display="flex">
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <p style={{ fontWeight: "bold" }}>Session <DoDisturbOnIcon sx={{ color: "red" }} /> from next subscription</p>

                            </Box>



                        ) : ("")}



                        {/* 
                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", }}>
                            <h4 style={{ margin: "0 auto", width: "auto" }}> <b>{subInfo[0]?.family}</b>  </h4></Card>


                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", display: "flex", justifyContent: "center" }}>
                            <CancelPresentationIcon sx={{ color: "red", marginBottom: "0" }} />               <h4 style={{ width: "100px", marginBottom: "0" }}> <b>{subInfo[0]?.UsedSessions}</b>  Used</h4>
                        </Card>


                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", display: "flex", justifyContent: "center" }}>
                            <h4 style={{ margin: "0 auto", width: "auto" }}> <CheckIcon sx={{ color: "green", border: "2px solid green", height: "18px", marginBottom: "7px" }} /> <b>{subInfo[0]?.RemainingSessions}</b>  Remains</h4>

                        </Card>


                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", display: "flex", justifyContent: "center" }}>
                            <h4 style={{ margin: "0 auto", width: "auto", alignItems: "cemter", textAlign: "center" }}>  <DateRangeIcon sx={{ marginBottom: "7px" }} /> last Check <b>{subInfo[0]?.LastCheck}</b>  </h4>

                        </Card>


                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", display: "flex", justifyContent: "center" }}>
                            <h4 style={{ margin: "0 auto", width: "auto", alignItems: "cemter", textAlign: "center" }}>  <DateRangeIcon sx={{ marginBottom: "7px" }} /> Ends in <b>{subInfo[0]?.EndDate}</b>  </h4>


                        </Card>

                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", display: "flex", justifyContent: "center" }}>
                            <h4 style={{ margin: "0 auto", width: "auto" }}> Registered at <b> {subInfo[0]?.branch}</b> </h4>



                        </Card>


                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", display: "flex", justifyContent: "center" }}>                                                                                                  
                        <h4 style={{ margin: "0 auto", width: "auto", color: "black", fontWeight: "bold" }}> <PaidIcon sx={{ marginBottom: "7px", color: "black" }} /> Discount <b> {subInfo[0]?.DiscountValue
                        }</b> </h4>




                        </Card>




                        <Card variant="outlined" sx={{ backgroundColor: "#dedede", borderRadius: "10px", height: "35px", alignItems: "center", textAlign: "center", border: "none", marginBottom: "5px", display: "flex", justifyContent: "center" }}>                                                                                                                                <h4 style={{ margin: "0 auto", width: "auto", color: "red", fontWeight: "bold" }}> <PaidIcon sx={{ marginBottom: "7px", color: "red" }} /> Debt <b> {subInfo[0]?.Remains}</b> </h4>





                        </Card>


 */}




                        <Container
                            variant="stacked"

                        >
                            <ColumnLayout columns={3} variant="text-grid">


                                <div>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <GoPackage size={20} style={{ color: "black", marginBottom: "0", marginRight: "7px", size: "12px" }} />   <h6 style={{ fontWeight: "bold", marginBottom: "0" }}>{subInfo[0]?.family} </h6>
                                    </div>

                                </div>




                                <div style={{ alignItems: "center", textAlign: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <DateRangeIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>End Date {subInfo[0]?.EndDate}  </label>
                                    </div>

                                </div>






                                <div style={{ alignItems: "center", textAlign: "center", margin: "0 auto" }}>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <FaRegistered size={23} sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", marginLeft: "0" }}>Registration Branch {subInfo[0]?.branch} </label>
                                    </div>

                                </div>


                                <div style={{ alignItems: "center", textAlign: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <DateRangeIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Last Check {subInfo[0]?.LastCheck} </label>
                                    </div>

                                </div>





                                <div style={{ alignItems: "center", textAlign: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <PaidIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Discount {subInfo[0]?.DiscountValue} </label>
                                    </div>

                                </div>

                                <div style={{ alignItems: "center", textAlign: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <PaidIcon sx={{ color: "black", marginBottom: "0", height: "20px", width: "20px", marginRight: "5px" }} />   <label style={{ fontWeight: "bold", margin: "0" }}>Debt {subInfo[0]?.Remains} </label>
                                    </div>

                                </div>






                                <div>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <CancelPresentationIcon sx={{ color: "red", marginBottom: "0", marginRight: "5px", width: "20px", height: "20px" }} />   <h6 style={{ fontWeight: "bold", margin: "0" }}>Used {subInfo[0]?.UsedSessions} </h6>
                                    </div>

                                </div>


                                <div style={{ alignItems: "center", textAlign: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "cemter", alignItems: "center", textAlign: "center" }}>
                                        <CheckIcon sx={{ color: "green", marginBottom: "0", fontWeight: "bold", border: "2px solid green", height: "15px", width: "20px", marginRight: "5px" }} />   <h6 style={{ fontWeight: "bold", margin: "0" }}>Remains {subInfo[0]?.RemainingSessions} </h6>
                                    </div>

                                </div>





                            </ColumnLayout>
                        </Container>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseInfo} style={{ backgroundColor: "white", color: "red", fontWeight: "bold" }}>Cancel</Button>

                        <LoadingButton
                            className="loading-button"
                            style={{ width: "100px", marginTop: "0", borderRadius: "7px", height: "35px", backgroundColor: "#00ab41", fontSize: "14px" }}
                            loading={buttonLoading}
                            loadingPosition="end"
                            variant="contained"
                            type="submit"
                            onClick={handleClassCheckIn}

                        >
                            <span style={{ color: "white" }}>Confirm</span>
                        </LoadingButton>



                    </DialogActions>
                </BootstrapDialog>
            )}
            <Drawer

                PaperProps={{
                    style: {
                        width: "30%"
                    }
                }}
                anchor="right" open={sideOpen} onClose={() => toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{ zIndex: "1000" }}


            >
                <DialogTitle>Modify</DialogTitle>

                <DialogContent>
                    <form onSubmit={(e) => changeTrainer(e)} style={{ border: "1px solid #e4e4e4", backgroundColor: "white", height: "600px", padding: "20px", width: "400px", margin: "0 auto" }}>
                        <TextField onChange={(e) => setClassName(e.target.value)} value={className} sx={{ marginBottom: "10px", width: "250px" }} id="outlined-basic" label="Class name" variant="outlined" />


                        <select required onChange={(e) => setChangedClassTrainer(e.target.value)} style={{ margin: "0 auto", width: "250px", marginBottom: "30px", height: "40px", textAlign: "center", backgroundColor: "white", border: "1px solid #e4e4e4", alignItems: "center" }} name="" id="">

                            <option value="" disabled selected>Trainer</option>
                            {trainer?.sort((a, b) => a.fullName.localeCompare(b.fullName)).map((type, index) => {
                                return (
                                    <option key={index} value={type.fullName}>{type.fullName}</option>
                                )
                            })}
                        </select>


                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={"Days"}
                            isMulti
                            options={weekDays}
                            onChange={setSelectedOption}
                            placeholder="Days"
                        />

                        <br />


                        <TimePicker sx={{ zIndex: "10000", width: "250px", margin: "0 auto", position: "relative" }} onChange={onChange} style={{ marginBottom: "10px", height: "40px", width: "250px" }} defaultValue={dayjs('12:08', format)} format={format} />


                        <div style={{ margin: "0 auto", alignItems: "center", textAlign: "center" }}>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <button className='confitm-button' style={{ width: "200px", marginBottom: "20px", height: "40px", borderRadius: "18px", bottom: "0px", position: "relative", margin: "0 auto" }} type="submit">Confirm</button>
                        </div>



                    </form>

                </DialogContent>

            </Dialog>


            {loading || classesLoading ? (<CircularProgress />) : (<>


                <div style={{ display: "flex" , marginBottom:"20px", }}>
                    <Button
                    sx={{backgroundColor:"#e4e4e4", color:"black", marginRight:"20px", width:"150px", fontWeight:"bold"}}
                        aria-label="filter"
                        aria-controls={openFilter ? "three-dots-menu" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        startIcon={<IoFilter />} // Add the icon before the text
                    >
                        {filter}
                    </Button>
                    <Menu
                        id="three-dots-menu"
                        anchorEl={anchorEl}
                        open={openFilter}
                        onClose={handleCloseFilter}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={() => handleOptionClick("All")}>All</MenuItem>
                        <MenuItem onClick={() => handleOptionClick("Today")}>Today</MenuItem>


                    </Menu>

                    <select
                        value={selectedBranch || ""} // Default to empty string if no branch is selected
                        onChange={(e) => setSelectedBranch(e.target.value)}
                        style={{
                            width: "150px",
                            height: "40px",
                            color: "black",
                            alignItems: "center",
                            textAlign: "center",
                            borderRadius: "7px",
                            backgroundColor: "#e4e4e4",
                            border: "none",
                        }}
                    >
                        <option value="" disabled>
                            Select Branch
                        </option>
                        {branches?.map((b, index) => (
                            <option key={index} value={b.name}>
                                {b.name}
                            </option>
                        ))}
                    </select>
                </div>



                <TableContainer component={Paper}>
                    <MUITABLE>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#e4e4e4', fontFamily: "poppins", borderTopLeftRadius: '10px' }}>Family</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#e4e4e4', fontFamily: "poppins", }}>Class</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#e4e4e4', fontFamily: "poppins", }}>Days</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#e4e4e4', fontFamily: "poppins", }}>Hours</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#e4e4e4', fontFamily: "poppins", }}>Trainer</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#e4e4e4', fontFamily: "poppins", }}>Branch</TableCell>
                                <TableCell style={{ backgroundColor: '#e4e4e4' }}></TableCell>
                                <TableCell style={{ backgroundColor: '#e4e4e4' }}></TableCell>

                                <TableCell style={{ backgroundColor: '#e4e4e4', borderTopRightRadius: '10px' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {classesData?.map((c) => (

                                <TableRow key={c.id}>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: "poppins", }}>{c.family}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: "poppins", }}>{c.Description}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: "poppins", }}>{c.Days}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: "poppins", }}>{c.Hours || c.hours}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: "poppins", }}>{c.Trainer}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: "poppins", }}>{c.Branch}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: '#23395d', color: 'white', borderRadius: "7px" }}
                                            startIcon={<CheckIcon sx={{ color: 'white' }} />}

                                            onClick={() => checkin(c.id, c.Description, c.family, c.Trainer)}
                                        >
                                            Check-in
                                        </Button>
                                    </TableCell>
                                    {Role === "Admin" && (
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: '#23395d', borderRadius: "7px", color: 'white' }}
                                                startIcon={<EditIcon sx={{ color: 'white' }} />} style={{ width: '150px', fontWeight: 'bold' }}
                                                onClick={() => Modify(c)}
                                            >
                                                Modify
                                            </Button>
                                        </TableCell>

                                    )}
                                    {Role === "Admin" && (
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
                                                startIcon={<DeleteIcon sx={{ color: 'white' }} />}
                                                onClick={() => Delete(c)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </MUITABLE>
                </TableContainer>

            </>)}






            {/* <ClassNav /> */}

            <ToastContainer
                autoClose={false}
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


        </div>

    )
}

export default ClassesPage