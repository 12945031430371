import { useState, useEffect, useRef, useReducer } from 'react'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "../styles/customerProfile.css"
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid, CardContent } from '@mui/material';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import Button from '@mui/material/Button';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FaAddressCard } from "react-icons/fa";
import BadgeIcon from '@mui/icons-material/Badge';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import $ from 'jquery';
import AcademyTable from '../components/customerProfile/academyData';
import GymData from '../components/customerProfile/gymData';
import ClassesData from '../components/customerProfile/classsData';
import AddDiet from '../components/dietComponents/addDietCustomer';
import ClinicData from '../components/customerProfile/clinicData';
import AddCustomerOTS from '../components/customerProfile/otsData';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import HistoryIcon from '@mui/icons-material/History';

import Navbar from '../components/navbar';
import { Cake, Email, LocationOn, Phone } from '@mui/icons-material';
import { DatePicker } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import { createStyles } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Transition } from 'react-transition-group';
import ModalJOY, { getModalUtilityClass, modalClasses } from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import LoadingButton from '@mui/lab/LoadingButton';
import WorkIcon from '@mui/icons-material/Work';
import moment from 'moment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RateModal from '../components/customerProfile/rateModal';
import ReturnToCustomer from '../components/customerProfile/returnToCustomerModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CameraModal from '../components/customerProfile/cameraModal';
import { FaCamera } from "react-icons/fa";

import DialogTitleJOY, { dialogTitleClasses, getDialogTitleUtilityClass } from '@mui/joy/DialogTitle';
import DialogContentJOY, { dialogContentClasses, getDialogContentUtilityClass } from '@mui/joy/DialogContent';
const API = process.env.REACT_APP_API

function CustomerProfile() {


  const days_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
  const [customerData, setCustomerData] = useState([]);
  const [isLoadingCustomerData, setisLoadingCustomerData] = useState()
  const [packages, setPackages] = useState([])
  const packagesDesc = []
  const params = useParams()
  const navigate = useNavigate()
  const [subcriptionData, setSubcriptionData] = useState({})
  const [isLoading, setIsloading] = useState()
  const [searchParams] = useSearchParams();
  const [paid, setPaid] = useState()
  const [RemainsGracePeriod, setGracePeriod] = useState()
  const [render, rerender] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [updatedName, setUpdatedName] = useState("")
  const [updatedPhone, setUpdatedPhone] = useState("")
  const [updatedNationalID, setUpdatedNationalID] = useState("")
  const [updatedAddress, setUpdatedAddress] = useState("")
  const [updatedSecondPhone, setUpdatedSecPhone] = useState("")
  const [updatedBirthDate, setUpdatedBirthdate] = useState()
  const [updatedWork, setUpdatedWork] = useState()
  const [gender, setGender] = React.useState('');

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };


  const [ModalOpen, setModalOpen] = React.useState(false);
  const [dataRender, setDataRender] = useState(false)
  const [variant, setVariant] = React.useState('outlined');
  const [color, setColor] = React.useState('neutral');
  const [Navvalue, setNavValue] = useState(0);
  const loggedBranch = localStorage.getItem("loggedBranch");
  const role = localStorage.getItem("Roles")
  const [value, setValue] = useState(1);
  const customerID = params.id
  const dateFormat = 'YYYY-MM-DD';
  const handleOpenQRModal = () => setOpen(true);
  const handleCloseQRModal = () => setOpen(false);
  const [qrLink, setQrLink] = useState('');
  const [btnLoading, setBtnLoading] = useState(false)
  const [mergeCustomerID, setMergeID] = useState()


  // return to customer 

  const [openReturnCustomer, setOpenRetrunCustomer] = React.useState(false);
  const handleOpenRetrunCustomer = () => setOpenRetrunCustomer(true);
  const handleCloseReturnCustomer = () => setOpenRetrunCustomer(false);


  // Age calcaulation

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const customerAge = calculateAge(customerData[0]?.birthDate)




  // camera modal 

  const [openCamera, setOpenCamera] = React.useState(false);
  const handleOpenCamera = () => setOpenCamera(true);
  const handleCloseCamera = () => setOpenCamera(false);
	const [isCameraOpen, setIsCameraOpen] = useState(false);


  const KeyGridItem = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
  });

  const ValueGridItem = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
  });
  const userData = [
    { icon: <PersonIcon />, value: customerData[0]?.Fullname },
    { icon: <Phone />, value: customerData[0]?.Phone },
    { icon: <Phone />, value: customerData[0]?.secondPhone },
    { icon: <BadgeIcon style={{ margin: "0" }} />, value: customerData[0]?.NationalID },
    { icon: <WorkIcon style={{ margin: "0" }} />, value: customerData[0]?.work },

    { icon: <LocationOn />, value: customerData[0]?.Address },


    { icon: <Cake />, value: `${customerAge} Years` },
  ];
  const onChange = (date, dateString) => {
    if (date && date.isValid()) { // Check if the moment date is valid
      setUpdatedBirthdate(date.format(dateFormat)); // Store as formatted string
    } else {
      console.error('Invalid date selected');
    }
  };



  // rate modal 

  const [openRateModal, setOpenRateModal] = useState(false);
  const handleOpenRate = () => setOpenRateModal(true);
  const handleCloseRate = () => setOpenRateModal(false);



  // generate QR code

  const generateLink = async () => {
    try {
      const response = await fetch(`${API}/generate-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerID }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate link');
      }

      const data = await response.json();
      setQrLink(data.link);
      handleOpenQRModal()
    } catch (error) {
      console.error(error);
      alert('Error generating link. Please try again.');
    }
  };


  // Merge Two customers 

  function mergeCustomers(e) {
    e.preventDefault()
    fetch(`${API}/mergeCustomers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'recieverID': customerID,
        "senderID": mergeCustomerID

      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == "200") {

          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",

          })
          setModalOpen(false)
          setDataRender(!dataRender)

        }
      })
  }








  const handleChange = (event, newValue) => {
    event.preventDefault();

    setValue(newValue);


  };


  const [isActive, setIsActive] = useState({
    id: 'divOne',
  })
  useEffect(() => {
  }, [isActive])
  const hideShowDiv = (e) => {
    setIsActive({
      id: e.target.id,
    })
  }


  const handleNavChange = (event, newValue) => {
    hideShowDiv(event)

    setNavValue(newValue);

  };



  function handleChangeName(e) {
    setUpdatedName(e.target.value)

  }
  function handleChangePhone(e) {
    setUpdatedPhone(e.target.value)

  }

  function handleChangeNationalID(e) {
    setUpdatedNationalID(e.target.value)

  }

  function handleChangedAddress(e) {
    setUpdatedAddress(e.target.value)

  }

  function handleChangedSecPhone(e) {
    setUpdatedSecPhone(e.target.value)

  }

  function handleChangedWork(e) {
    setUpdatedWork(e.target.value)

  }



  const [editInfo, setOpenInfo] = React.useState(false);

  const handleClickOpenInfo = () => {
    setOpenInfo(true);
  };
  const handleCloseInfo = () => {
    setOpenInfo(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const styles = createStyles({
    button: {
      // main styles,
      "&:focus": {
        color: "red"
      }
    }
  })









  const icon = (
    <Paper >

      <input onChange={(e) => setPaid(e.target.value)} style={{ marginBottom: "10px" }} type="text" placeholder='Paid' />

      <select onChange={(e) => setGracePeriod(e.target.value)} style={{ backgroundColor: "whitesmoke", color: "black" }} name="" id="">
        <option value="" selected disabled>Grace period (days)</option>

        {days_list.map((d) => {

          return (
            <option value={d}>{d}</option>
          )
        })}
      </select>
    </Paper>
  );


  useEffect(function () {
    async function fetchData() {
      try {
        setisLoadingCustomerData(true)
        const res = await fetch(`${API}/customers/getCustomerByID/${customerID}`)
        const data = await res.json()
        setCustomerData(data.customer)
        setUpdatedName(data.customer[0]?.Fullname)
        setUpdatedPhone(data.customer[0]?.Phone)
        setUpdatedNationalID(data.customer[0]?.NationalID)
        setUpdatedAddress(data.customer[0]?.Address)
        setUpdatedSecPhone(data.customer[0]?.secondPhone)
        setUpdatedBirthdate(data.customer[0]?.birthDate)
        setUpdatedWork(data.customer[0]?.work)
        setGender(data.customer[0]?.Gender)



      } catch (e) {
        console.log(e)
      } finally {
        setisLoadingCustomerData(false)

      }
    } fetchData()
  }, [dataRender])









  packages.map((pack) => {
    packagesDesc.push(pack.describtion)
  })





  function UpdateCustomerInfo() {

    fetch(`${API}/updateCustomerInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'customerID': customerID,
        'name': updatedName,
        'phone': updatedPhone,
        "nationalID": updatedNationalID,
        "secPhone": updatedSecondPhone,
        "Address": updatedAddress,
        "BirthDate": updatedBirthDate,
        "work": updatedWork,
        "gender": gender
      })
    }).then(response => response.json())
      .then(result => {
        if (result.status == "200") {

          toast.success(`${result.Message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",

          })
          handleCloseInfo()
          setDataRender(!dataRender)

        }
      })
  }





  $(".category-button").focusin(function () {
    $('.category-button').css({
      'background-color': '#081534', "border": "none",
      "color": "white",
      "font-weight": "bold",
      "border-bottom": "12px",
      "width": "150px",
      "height": "30px"
    }); // Remove Before set color to selected button
    $(this).css({
      "background-color": "white",
      "color": "#081534",
      "border-radius": "12px",
      "width": "150px"
    }

    );
  });
  const style = {
    position: 'absolute',
    top: '50%',
    display: "inline-block",
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: "20px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center', // Center text
  };


  { console.log(gender) }

  { customerData.length == "0" && <h1 style={{ margin: "0 auto" }}>No customer found</h1> }
  return (
    <>
      <CameraModal dataRender={dataRender} setDataRender={setDataRender} ToastContainer={ToastContainer} customerID={customerID} isCameraOpen={isCameraOpen} openCamera={openCamera} handleCloseCamera={handleCloseCamera} setOpenCamera={setOpenCamera} handleOpenCamera={handleOpenCamera} />

      <Transition in={ModalOpen} timeout={400}>
        {(state) => (
          <ModalJOY
            keepMounted
            open={!['exited', 'exiting'].includes(state)}
            onClose={() => setModalOpen(false)}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                    entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === 'exited' ? 'hidden' : 'visible',
            }}
          >
            <ModalDialog
              sx={{
                opacity: 0,
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >


              <form onSubmit={(e) => mergeCustomers(e)} style={{ backgroundColor: "white", width: "100%", maxWidth: "400px", paddingTop: "0", height: "400px", margin: "0 auto" }}>
                <TextField onChange={(e) => setMergeID(e.target.value)} sx={{ marginBottom: "10px" }} label="Customer ID" variant="outlined" fullWidth /> <br />
                <LoadingButton type="submit" btnLoading sx={{
                  width: "100%", maxWidth: "150px", backgroundColor: "black", color: "white", height: "40px", borderRadius: "10px", fontWeight: "bold", marginTop: "100px"
                  , ":hover": {
                    backgroundColor: "black",
                    color: "white"
                  }
                }}>
                  Submit
                </LoadingButton>
              </form>
            </ModalDialog>
          </ModalJOY>
        )}
      </Transition>

      <Navbar />
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <RateModal customerID={customerID} openRateModal={openRateModal} setOpenRateModal={setOpenRateModal} handleOpenRate={handleOpenRate} handleCloseRate={handleCloseRate} />

      {/* Edit customer info */}
      <BootstrapDialog
        onClose={handleCloseInfo}
        aria-labelledby="customized-dialog-title"
        open={editInfo}
        sx={{ padding: "20px", zIndex: "1000" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Edit customer info
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ padding: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'stretch' }}>
            {/* Name Input */}
            <TextField
              onChange={(e) => handleChangeName(e)}
              value={updatedName}
              label="Name"
              variant="outlined"
              fullWidth
            />
            {/* Gender Select */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">Gender</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={gender}
                label="Gender"
                onChange={handleChangeGender}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>

            {/* Phone Input */}
            <TextField
              onChange={(e) => handleChangePhone(e)}
              value={updatedPhone}
              label="Phone"
              variant="outlined"
              fullWidth
            />

            {/* Second Phone Input */}
            <TextField
              onChange={(e) => handleChangedSecPhone(e)}
              value={updatedSecondPhone}
              label="Second Phone"
              variant="outlined"
              fullWidth
            />

            {/* National ID Input */}
            <TextField
              onChange={(e) => handleChangeNationalID(e)}
              value={updatedNationalID}
              label="National ID"
              variant="outlined"
              fullWidth
            />

            {/* Address Input */}
            <TextField
              onChange={(e) => handleChangedAddress(e)}
              value={updatedAddress}
              label="Address"
              variant="outlined"
              fullWidth
            />

            {/* Work Input */}
            <TextField
              onChange={(e) => handleChangedWork(e)}
              value={updatedWork}
              label="Work"
              variant="outlined"
              fullWidth
            />

            {/* Birth Date Picker */}
            <DatePicker
              defaultValue={updatedBirthDate ? dayjs(updatedBirthDate, 'YYYY-MM-DD') : null}
              required
              format={dateFormat}
              style={{ width: '100%' }}
              onChange={onChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={UpdateCustomerInfo}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {isLoadingCustomerData ? (
        <CircularProgress sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }} />
      ) : (
        <Box sx={{ width: "80%", margin: "Auto" }} >

          {/* Top Container */}
          <Box sx={{
            top: "0",
            width: "auto",
            backgroundColor: "whitesmoke",
            border: "1px solid gainsboro",
            margin: "auto",
            height: "auto",
            alignItems: "center",
            textAlign: "center",
            padding: "10px",
            color: "black",
            marginBottom: "40px",
            borderRadius: "8px",
            boxShadow: "0 8px 20px rgba(99, 99, 99, 0.19), 0 2px 2px rgba(148, 148, 148, 0.23)",
          }}>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleCloseQRModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 1500,
                },
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <QRCodeCanvas value={qrLink} size={256} />
                  <Button
                    className="print-button"
                    variant="contained"
                    onClick={() => window.print()}
                    sx={{ width: "80%", margin: "15px auto", fontWeight: "bold" }}
                  >
                    Print
                  </Button>
                </Box>
              </Fade>
            </Modal>

            <Card variant={variant} color={color} sx={{ width: "auto", height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Box
                  sx={{
                    position: "relative",
                    width: 150,
                    height: 150,
                    marginBottom: "10px",
                    border: "1px solid #e4e4e4",
                    borderRadius: "10%",
                    overflow: "hidden",
                    "&:hover .camera-icon": {
                      opacity: 1,
                    },
                  }}
                >
                  <img
                    src={!customerData[0]?.avatar || customerData[0]?.avatar == "undefined" ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" : customerData[0]?.avatar} alt="Profile"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                  <IconButton
                    className="camera-icon"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "white",
                      opacity: 0,
                      transition: "opacity 0.3s ease",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                      },
                    }}
                  >
                    <FaCamera onClick={() =>  {setIsCameraOpen(true) ; handleOpenCamera() }} />
                  </IconButton>
                </Box>
                <Card sx={{
                  borderRadius: "20%",
                  backgroundColor: "#e4e4e4",
                  padding: "4px",
                  minWidth: "100px",
                  maxWidth: 345,
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'auto',
                  borderRadius: "10px",
                  boxShadow: 3,
                  border: "none"
                }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "50px", fontFamily: "Cairo" }}>
                    {customerData[0]?.customerID}
                  </Typography>
                </Card>

                <Grid container direction="column" alignItems="center" spacing={2}>
                  {userData.map((item, index) => (
                    <Grid container item xs={12} md={6} lg={4} textAlign="center" key={index} alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                          {item.icon}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontFamily="Cairo" fontSize="20px" variant="body2" color="text.primary">
                          <strong>{item.value}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>

              <Typography variant="body2">Added by <strong>{customerData[0]?.employee}</strong></Typography>
            </Card>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                textAlign: "center",
                marginTop: 2,
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >


<Button
                onClick={() => navigate(`/history/${customerID}`)}
                sx={{
                  margin: "5px",
                  width: { xs: "100%", sm: "170px" },
                  backgroundColor: "#e4e4e4",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  color: "black",
                  fontSize: { xs: "10px", sm: "12px" }
                }}
                startIcon={<HistoryIcon />}
              >
                History
              </Button>
              {role === "Admin" && (
                <Button
                  sx={{
                    margin: "5px",
                    backgroundColor: "#e4e4e4",
                    borderRadius: "7px",
                    width: { xs: "100%", sm: "170px" },
                    fontWeight: "bold",
                    color: "black",
                    fontSize: { xs: "10px", sm: "12px" }
                  }}
                  onClick={handleClickOpenInfo}
                  startIcon={<EditIcon />}
                >
                  Edit Info
                </Button>
              )}

              <Button
                onClick={() => navigate(`/health/${customerID}`)}
                sx={{
                  margin: "5px",
                  width: { xs: "100%", sm: "170px" },
                  backgroundColor: "#e4e4e4",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  color: "black",
                  fontSize: { xs: "10px", sm: "12px" }
                }}
                startIcon={<MonitorHeartIcon />}
              >
                Medical Record
              </Button>

              <Button
                sx={{
                  margin: "5px",
                  backgroundColor: "#3b82f680",
                  borderRadius: "7px",
                  width: { xs: "100%", sm: "170px" },
                  fontWeight: "bold",
                  color: "black",
                  fontSize: { xs: "10px", sm: "12px" }
                }}
                onClick={generateLink}
                startIcon={<QrCode2Icon />}
              >
                Generate QR
              </Button>

              {role === "Admin" && (
                <Button
                  sx={{
                    margin: "5px",
                    backgroundColor: "#d9d9db",
                    borderRadius: "7px",
                    width: { xs: "100%", sm: "170px" },
                    fontWeight: "bold",
                    color: "black",
                    fontSize: { xs: "10px", sm: "12px" }
                  }}
                  onClick={() => setModalOpen(true)}
                  startIcon={<AutorenewIcon />}
                >
                  Merge
                </Button>
              )}

              {(role === "Admin" || role === "General Manager") && (
                <Button
                  sx={{
                    margin: "5px",
                    backgroundColor: "#d9d9db",
                    borderRadius: "7px",
                    width: { xs: "100%", sm: "170px" },
                    fontWeight: "bold",
                    color: "black",
                    fontSize: { xs: "10px", sm: "12px" }
                  }}
                  onClick={() => setOpenRateModal(true)}
                  startIcon={<TrendingUpIcon />}
                >
                  Rate
                </Button>
              )}
            </Box>
          </Box>

          <Box sx={{ width: "auto", width: "fit-content", margin: "auto" }}>
            <Box sx={{ width: 'fit-content', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: "0 auto", width: "fit-content" }}>
                  <TabList sx={{ margin: "0 auto", fontWeight: "bold" }} onChange={handleChange} aria-label="lab API tabs example">
                    <Tab sx={{ fontWeight: "bold", color: "black" }} label="GYM" value="1" />
                    <Tab sx={{ fontWeight: "bold", color: "black" }} label="Group Fitness" value="2" />
                    <Tab sx={{ fontWeight: "bold", color: "black" }} label="Academy" value="3" />
                    <Tab sx={{ fontWeight: "bold", color: "black" }} label="Diet" value="4" />
                    <Tab sx={{ fontWeight: "bold", color: "black" }} label="Clinic" value="5" />

                    <Tab sx={{ fontWeight: "bold", color: "black" }} label="Attendance" onClick={() => navigate(`/customer-attendance/${customerID}`)} />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <GymData age={customerAge} setIsActive={setIsActive} isActive={isActive} subcriptionData={subcriptionData} render={render} rerender={rerender} customerID={customerID} />
                </TabPanel>
                <TabPanel value="2">
                  <ClassesData isActive={isActive} loggedBranch={loggedBranch} rerender={rerender} render={render} customerID={customerID} />
                </TabPanel>
                <TabPanel value="3">
                  <AcademyTable loggedBranch={loggedBranch} rerender={rerender} render={render} customerID={customerID} isActive={isActive} hideShowDiv={hideShowDiv} />
                </TabPanel>
                <TabPanel value="4">
                  <AddDiet render={render} rerender={rerender} customerID={customerID} />
                </TabPanel>

                <TabPanel value="5">
                  <ClinicData render={render} rerender={rerender} customerID={customerID} />
                </TabPanel>
              </TabContext>
            </Box>

            <AddCustomerOTS rerender={rerender} hideShowDiv={hideShowDiv} isActive={isActive} />
          </Box>
        </Box>
      )}
    </>

  )

}




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export default CustomerProfile
