import { useState, useEffect, useRef, useReducer } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import "../../styles/customerProfile.css"
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaidIcon from '@mui/icons-material/Paid';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';


import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReturnToCustomerClass from './returnToCustomerClassModal';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogContentText from '@mui/material/DialogContentText';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';


import EditIcon from '@mui/icons-material/Edit';


import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import moment from 'moment-timezone';



import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import CancelIcon from '@mui/icons-material/Cancel';

const API = process.env.REACT_APP_API



function ClassesData({ isActive, customerID, render, rerender, loggedBranch }) {
	const cancelCause = useRef()

	const [customerClass, setCustomerClass] = useState([])
	const [isClassesLoading, setisClassesLoading] = useState()
	const [selectedClass, setSelectedClass] = useState()
	const [ClassPackageDesc, setClassPackageDesc] = useState()
	const [classPkgLoading, setClassPckLoading] = useState()
	const [descLoading, setDescsLoading] = useState()
	const [selectedPackageID, setSelectedPackID] = useState()
	const [Discount, setDiscount] = useState("0")
	const [branch, setBranch] = useState()
	const [allClassesFamilies, getAllClassFamilies] = useState()
	const [familiesLoading, setFamiliesLoading] = useState()
	const [familyID, setSelectedFamily] = useState()
	const params = useParams()
	const [searchParams] = useSearchParams();
	const userBranch = searchParams.get('userBranch');
	const [debtValue, setDebtValue] = useState()
	const [open, setOpen] = React.useState(false);
	const [classesPackages, setClassesPackages] = useState([])
	const [classPackagesLoading, setClassPackageesLoading] = useState()
	const [paid, setPaid] = useState()
	const [RemainsGracePeriod, setGracePeriod] = useState()
	const [changedClassPackageID, setChangeedClassPackageID] = useState()
	const [buttonLoading, setButtonLoading] = useState()
	const [subID, setSubID] = useState()
	const [activationOpen, setActivationOpen] = React.useState(false);
	const [archived, setArchived] = useState()
	const [subStatus, setSubStatus] = useState()
	const [subscriptionID, setSubscriptionID] = useState()
	const [debtOpen, setDebtOpen] = React.useState(false);
	const [remainingSessions, setRemaininsSessions] = useState()
	const [deleteSub, setDeleteSub] = React.useState(false);
	const [selectedPT, setSelectedPT] = useState()
    const discountRef = useRef()

	const invitedName = useRef()
	const invitedPhone = useRef()
	const invitedCustomerName = useRef()
	const invitedCustomerPhone = useRef()
	const [increaseSub, setIncreaseSub] = useState(false)
	const [PTLoading, setPTLoading] = useState()
	const [btnLoading, setBtnLoading] = useState()

	const [invtiationOpen, setInvitationOpen] = React.useState(false);
	const employee = localStorage.getItem("User")
	const [subcriptionData, setSubcriptionData] = useState([])
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))



	// return to customer (class)

	const [openReturnCustomerClass, setOpenRetrunCustomerClass] = React.useState(false);
	const handleOpenRetrunCustomerClass = () => setOpenRetrunCustomerClass(true);
	const handleCloseReturnCustomerClass = () => setOpenRetrunCustomerClass(false);
    const [remainingValue, setRemainingValue] = useState()




	const [PT, setPT] = useState()

	const [Role, setRole] = useState()


	// purge class sub 

	const [openPurge, setOpenPurge] = useState(false);

	function PurgeConfirmation() {
		return (
			<Modal open={openPurge} onClose={() => setOpenPurge(false)}>
				<ModalDialog variant="outlined" role="alertdialog">
					<DialogTitle sx={{ padding: "0", fontWeight: "bold" }}>
						<WarningRoundedIcon />
						Confirmation
					</DialogTitle>
					<Divider />
					<DialogContent sx={{ padding: "0" }}>
						Are you sure you want to entirely delete the subscription?
					</DialogContent>
					<DialogActions>

						<Button variant="plain" color="neutral" onClick={() => setOpenPurge(false)}>
							Cancel
						</Button>

						<Button variant="solid" sx={{
							backgroundColor: '#C41C1C',
							color: "white",
							":hover": {
								backgroundColor: '#C41C1C',
								color: "white",
							}
						}} onClick={() => handleSubPurge()}>
							Delete
						</Button>
					</DialogActions>
				</ModalDialog>
			</Modal>
		)
	}


	function handleSubPurge() {
		fetch(`${API}/purgeClassSub`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({

				"subID": subID,
			})
		}).then(response => response.json())
			.then(result => {
				console.log(result)
				if (result.status == "200") {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					setOpenPurge(false)


				} else {
					toast.error(`Something went wrong`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

					setOpenPurge(false)

				}
			})

	}



	const formik = useFormik({
		initialValues: {
			selectedFamily: '',
			selectedPackID: '',
			selectedPT: '',
			discount: 0,
			paid: '',
			gracePeriod: '',
			debt: false, // for managing debt visibility
			graceDays: ''

		},
		validationSchema: Yup.object({
			selectedFamily: Yup.string().required('Family is required'),
			selectedPackID: Yup.string().required('Package is required'),
			selectedPT: Yup.string().optional(),
			discount: Yup.string(),
			paid: Yup.string().optional(),


		}),
		onSubmit: (values) => {

			console.log(values)

			if (values.paid !== "" && !values.graceDays) {
				formik.setErrors({ graceDays: "Grace Days is required" });
				return; // Stop submission
			}
			handleClientClassSubscription(values);
		},
	});



	useEffect(() => {
		const items = localStorage.getItem('Roles')
		if (items) {
			setRole(items);
		}
	}, []);



	const paybackvalue = useRef()
	const PayDebtValue = useRef()
	const sessionsRef = useRef()
	const daysRef = useRef()





	const handleInvitationClose = () => {
		setInvitationOpen(false);
	};
	const handleInvitationOpen = () => {
		setInvitationOpen(true);
	};

	const payDebtRef = useRef()

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		'& .MuiDialogContent-root': {
			padding: theme.spacing(2),
		},
		'& .MuiDialogActions-root': {
			padding: theme.spacing(1),
		},
	}));


	const [checked, setChecked] = React.useState(false);
	const days_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
	const changePackageRef = useRef()


	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleDebtOpen = () => {
		setDebtOpen(true);
	};
	const handleDebtClose = () => {
		setDebtOpen(false);
	};


	function handleClick() {
		PayClassDebt()
		setButtonLoading(true);
	}

	const handleClickOpenActivation = () => {
		setActivationOpen(true);
	};

	const handleCloseActivation = () => {
		setActivationOpen(false);
	};


	const handleClickOpenArchive = () => {
		setArchived(true);
	};

	const handleCloseArchive = () => {
		setArchived(false);
	};
	function subStatusAndID(x, y) {
		setSubStatus(x)
		setSubID(y)
		handleClickOpenActivation()


	}
	function subStatusAndIDArchive(x, y) {
		setSubStatus(x)
		setSubID(y)
		handleClickOpenArchive()


	}


	const handleDeleteSubOpen = () => {
		setDeleteSub(true);
	};


	function subStatusAndIDCancel(x, y) {
		setSubStatus(x)
		setSubID(y)


	}
	function subStatusAndIDArchive(x, y) {
		setSubStatus(x)
		setSubID(y)
		handleClickOpenArchive()


	}


	const handleDeleteSubClose = () => {
		setDeleteSub(false);
	};


	const handleIncreaseSubClose = () => {
		setIncreaseSub(false);
	};

	const handleIncreaseSubOpen = () => {
		setIncreaseSub(true);
	};

	function PayClassDebt(e) {
		e.preventDefault()
		fetch(`${API}/payClassDebt`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'debtValue': PayDebtValue.current.value,
				"subscriptionID": subscriptionID,
				"branch": loggedBranch


			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					rerender(!render)

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

					setButtonLoading(false)
					handleDebtClose()

				} else if (result.status == 400) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}
			})

	}



	function handleCustomerInvitation(e) {
		e.preventDefault()
		fetch(`${API}/inviteCustomerClass`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'subID': subID,
				'invitedName': invitedCustomerName.current.value,
				'invitedPhone': invitedCustomerPhone.current.value,
				'type': "class"
			})

		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})


					handleInvitationClose()
					rerender(!render)

				}


			})
	}


	function cancelClassSub(e) {
		e.preventDefault()
		fetch(`${API}/cancelClassSub`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'SubID': subID,
				"paybackvalue": paybackvalue.current.value,
				"cause": cancelCause.current.value,
				"branch": loggedBranch


			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleDeleteSubClose()


				}
			})
	}

	function HandleGClassPackageChange(e) {
		e.preventDefault()
		fetch(`${API}/changeClassPack`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'packageID': changePackageRef.current.value,
				"subID": subID,
				"discount" : discountRef.current.value
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {

					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleClose()


				}
			})

	}
	function handleClientClassSubscription(values) {
		setBtnLoading(true)
		fetch(`${API}/subscribeToClass`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				"CustomerID": customerID,
				"UsedSessions": "0",
				"selectedPackageID": values.selectedPackID,
				"BranchID": branch,
				"DiscountValue": values.discount,
				"ClassPackageDesc": selectedClass,
				"familyID": values.selectedFamily,
				"branch": loggedBranch,
				"Paid": values.paid,
				"RemainsGracePeriod": values.graceDays,
				"Trainer": values.selectedPT,
				"employee": employee

			}),
		}).then(response => response.json())
			.then(result => {
				if (result.status === 200) {
					// Directly update the subscription data state
					setSubcriptionData(prevData => {
						// Check if prevData is an array
						if (Array.isArray(prevData)) {
							return [...prevData, result.newSubscription[0]];
						} else {
							console.error('prevData is not an array:', prevData);
							return [result.newSubscription[0]]; // fallback to create a new array
						}
					})

					setTimeout(() => {
						setBtnLoading(false)
					}, 1500);
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				} else {
					setBtnLoading(false)
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
				}

			})

	}



	function updateGymSubStatus() {
		fetch(`${API}/updateClassSubStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'subscriptionID': subID,
				'status': subStatus,
				"customerID": customerID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleCloseActivation()

				} else if (result.status == "402") {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				}
			})
	}


	function archiveSub() {
		fetch(`${API}/ArchiveClassSub`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'subscriptionID': subID,
				'status': subStatus,
				"customerID": customerID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})
					rerender(!render)
					handleCloseArchive()

				} else if (result.status == "402") {
					toast.error(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					})

				}
			})
	}




	function ClassDataComponent({ sub }) {
		return (

			<Card orientation="vertical" variant="outlined" sx={{
				position: 'relative',
				transition: 'transform 0.3s, box-shadow 0.3s',
				padding: "20px",
				paddingTop: "50px", // Extra space for the button
				width: "auto",
				minWidth: "fit-content",
				margin: "9px auto"
			}}>

				<Dialog
					open={archived}

					onClose={handleCloseArchive}
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseArchive}>cancel</Button>
						<Button onClick={archiveSub}>Archive</Button>
					</DialogActions>
				</Dialog>

				<BootstrapDialog
					onClose={handleIncreaseSubClose}
					open={increaseSub}
					aria-labelledby="customized-dialog-title"



				>
					<IconButton
						aria-label="close"
						onClick={handleIncreaseSubClose}
						sx={{

							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>


					<DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
						<h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Increase Subscription</h5>


					</DialogContent>

					<DialogContent sx={{ margin: "0 auto", height: "190px" }}>
						<img style={{ width: "150px", margin: "0 auto", marginBottom: "0" }} src='/add.png' />
					</DialogContent>

					<DialogContent sx={{ margin: "0 auto", marginTop: "0" }}>
						<form onSubmit={(e) => handleIncreaseClassQuota(e)} style={{ height: "150px", width: "200px", backgroundColor: "white", padding: "5px", marginLeft: "0", marginBottom: "10px" }}>
							<input ref={sessionsRef} required type='text' placeholder='Num of sessions' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />
							<input ref={daysRef} required type='text' placeholder='Days' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} />

							<button type='submit' style={{ backgroundColor: "black", color: "white", margin: "0 auto" }} autoFocus >
								Confirm
							</button>
						</form>
					</DialogContent>





				</BootstrapDialog>


				{/* end of increase quota  */}
				{Role == "Admin" || Role == "admin"  ? ( <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
    
    <IconButton 
    backgroundColor="white"
         aria-label="cancel" 
         sx={{
           color: 'grey', 
           
         }}
         onClick={() => {
			setSubID(sub?.ClassSubscritionID)
			setOpenPurge(true);
         }}    >
         <ClearIcon />
       </IconButton>
     </Box>) : ("")}

				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-around", }} fontWeight="md" textColor="success.plainColor">
						<div className='package-name'>
							<div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Package Name</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", alignItems: "center", textAlign: "center" }}>{sub?.family}</label></div>

						</div>



						<div className='package-status'>

							<div style={{ marginLeft: "12px", fontSize: "14px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px", marginLeft: "20px" }}>Status</label></div>
							{sub?.status == "inactive" ? (<div onClick={() => subStatusAndID("active", sub.ClassSubscritionID)} style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "red", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {sub?.status}</label></div>
							) : (<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex", marginRight: "20px", backgroundColor: "green", color: "white", borderRadius: "7px", width: "100px", alignItems: "center", textAlign: "center", padding: "2px" }}>  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", margin: "0 auto" }}> {sub?.status}</label></div>
							)}
						</div>


						<div className='package-used'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}> <CancelPresentationIcon style={{ height: "20px", size: "30px", color: "darkred" }} />  <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Used Sessions</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.UsedSessions}</p></div>

						</div>


						<div className='package-remaining'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>  <CheckBoxIcon style={{ height: "20px", size: "30px", color: "green" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Remaining Sessions</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.RemainingSessions}</p></div>

						</div>

						<div className='pack-endDate'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <CalendarMonthIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Package End Date</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.EndDate}</p></div>

						</div>

						{sub?.Trainer ? (<div className='pack-endDate'>
							<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Personal Trainer</label></div>
							<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}> {sub?.Trainer}</p></div>

						</div>) : ("")}


						{sub?.employee ? (
							<div className='pack-endDate'>
								<div style={{ marginLeft: "12px", fontSize: "14px", display: "flex" }}>   <PersonAddIcon style={{ height: "20px", size: "30px", color: "grey" }} /> <label style={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "20px" }}>Employee</label></div>
								<div style={{ marginLeft: "12px", fontSize: "14px", alignItems: "center", textAlign: "center" }}>  <p style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{sub?.employee}</p></div>

							</div>
						) : ("")}




					</div>

					{/* <Button style={{ margin: "0 auto", whiteSpace: "nowrap", border: "1px solid red", color: "red", fontWeight: "bold", width: "120px", fontSize: "10px" }} onClick={() => { setSubID(sub.subscriptionid); handleClickOpen() }} variant="outlined">
						change package
					</Button> */}
				</CardContent> <br />

				{sub?.Remains != 0 ? (

<List sx={{ width: '100%', border: "1px solid #e4e4e4", bgcolor: 'background.paper', display: "flex", borderRadius: "10px", margin: "0 auto" }}>
	<ListItem>
		<ListItemAvatar>
			<Avatar>
				<MoneyOffIcon sx={{ color: "black" }} />

			</Avatar>
		</ListItemAvatar>
		<>
			<label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`Paid ${sub?.Paid}  `}</label>

		</>
	</ListItem>
	<ListItem>
		<ListItemAvatar>
			<Avatar>
				<AttachMoneyIcon sx={{ color: "black" }} />
			</Avatar>
		</ListItemAvatar>
		<label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`Remains ${Math.abs(sub?.Remains)}  `}</label>
	</ListItem>


	<ListItem>
		<ListItemAvatar>
			<Avatar>
				<DateRangeIcon sx={{ color: "black" }} />
			</Avatar>
		</ListItemAvatar>
		<label style={{ width: "40px", fontWeight: "bold", whiteSpace: "nowrap", }} > {`  ${sub?.RemainsGraceEndDate}  `}</label>

	</ListItem>



	<ListItem>



	{ 
	sub?.Remains > 0 && 
	(["admin", "receptionist"].includes(Role.toLowerCase())) ? (
	  <Button 
	  style={{width:"120px", border: "2px solid green",borderRadius:"7px", color: "green", fontWeight: "bold" }} 
	  onClick={() => { 
		  setSubscriptionID(sub.ClassSubscritionID); 
		  handleDebtOpen(); 
		}} 
		variant="outlined" 
		startIcon={<PaidIcon sx={{ color:'green'}} />}
	  >
		Pay
	  </Button>
	) : ("")
  }

		

   { 
	sub?.Remains < 0 && 
	(["admin"].includes(Role.toLowerCase())) ? (
	  <Button 
	  style={{ width:"120px", border: "2px solid red", color: "red",borderRadius:"7px", fontWeight: "bold" }} 
	  onClick={() => { 
		  setSubscriptionID(sub.ClassSubscritionID); 
		  setRemainingValue(sub.Remains)
		  handleOpenRetrunCustomerClass(); 
		}} 
		variant="outlined" 
		startIcon={<PaidIcon sx={{ color:'red'}} />}
	  >
		Return
	  </Button>
	) : ("")
  }
  


	</ListItem>




</List>) : ("")}
				{sub?.RemainingSessions == "0" || new Date(endDate) >= new Date(sub?.EndDate) ? (<Button onClick={() => subStatusAndIDArchive("archived", sub?.ClassSubscritionID)} style={{ width: "100px", margin: "0 auto", border: "2px solid black", color: "black", fontWeight: "bold" }} variant="outlined" startIcon={<ArchiveIcon />}>Archive</Button>) : ("")
				}

				<div style={{ display: "flex", justifyContent: "space-around" }}>

					{Role == "Admin" || Role == "admin" || Role == "Supervisor" ? (<Button style={{ whiteSpace: "nowrap", border: "1px solid darkblue", color: "red", fontWeight: "bold", fontSize: "10px" }} onClick={() => { setSubID(sub?.ClassSubscritionID); handleClickOpen() }} variant="outlined">
						<EditIcon sx={{ color: "darkblue" }} />

					</Button>) : ("")}


					{Role == "Admin" || Role == "admin" ? (<Button onClick={() => { setRemaininsSessions(sub?.RemainingSessions); subStatusAndIDCancel("canceled", sub?.ClassSubscritionID); handleDeleteSubOpen() }} sx={{ border: "1px solid red" }} variant="outlined">
						<CancelIcon sx={{ color: "Red" }} />

					</Button>) : ("")}


					{Role == "Admin" || Role == "admin" || Role == "General Manager" ? (

						<Button onClick={() => { setSubID(sub?.ClassSubscritionID); handleIncreaseSubOpen() }} sx={{ border: "1px solid green" }} variant="outlined">
							<AddCircleOutlineIcon sx={{ color: "Green" }} />

						</Button>) : ("")}
					{sub?.invitation == "Available" ? (<Tooltip title={<Typography fontSize={15}>invite</Typography>}>  <Button onClick={() => { setSubID(sub.ClassSubscritionID); handleInvitationOpen() }} sx={{ border: "1px solid #003b46" }} variant="outlined"> <PersonAddIcon sx={{ color: "#003b46" }} />  </Button> </Tooltip>) : ("")}


				</div>
			</Card>



		)
	}



	useEffect(function () {
		async function fetchSubOfCustomer() {
			try {
				setClassPckLoading(true)
				const res = await fetch(`${API}/getAllClassesSubscribers/${customerID}`)
				const data = await res.json()
				setSubcriptionData(data.result)

			} catch (e) {
				console.log(e)
			} finally {
				setClassPckLoading(false)
			}
		} fetchSubOfCustomer()
	}, [render])

	useEffect(function () {
		async function fetchPTs() {
			try {
				setPTLoading(true)
				const res = await fetch(`${API}/getAllPTrainers`)
				const data = await res.json()
				console.log(data)
				setPT(data.result)
			} catch (e) {
				console.log(e)
			} finally {
				setPTLoading(false)
			}
		} fetchPTs()

	}, [])


	// useEffect(function () {
	// 	async function fetchSubOfCustomer() {
	// 		try {
	// 			setDescsLoading(true)
	// 			const res = await fetch(`${API}/allClassPkgDesc`)
	// 			const data = await res.json()
	// 			setClassPackageDesc(data.Descs)

	// 		} catch (e) {
	// 			console.log(e)
	// 		} finally {
	// 			setDescsLoading(false)
	// 		}
	// 	} fetchSubOfCustomer()
	// }, [])

	useEffect(function () {
		async function fetchClassPackages() {
			try {
				setClassPackageesLoading(true)
				const res = await fetch(`${API}/getAllClassPackages`)
				const data = await res.json()
				setClassesPackages(data.classePackages)

			} catch (e) {
				console.log(e)
			} finally {
				setClassPackageesLoading(false)
			}
		} fetchClassPackages()
	}, [])

	useEffect(function () {
		async function fetchFamilies() {
			try {
				setFamiliesLoading(true)
				const res = await fetch(`${API}/getAllClassFamilies`)
				const data = await res.json()
				getAllClassFamilies(data.data)

			} catch (e) {
				console.log(e)
			} finally {
				setFamiliesLoading(false)
			}
		} fetchFamilies()
	}, [])

	const handleChange = () => {
		setChecked((prev) => !prev);
	};



	function handleIncreaseClassQuota(e) {
		e.preventDefault()
		fetch(`${API}/increaseClassSub`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'customerID': customerID,
				'sessions': sessionsRef.current.value,
				'days': daysRef.current.value,
				"subID": subID
			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == 200) {
					toast.success(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						autoClose: false
					})
					handleIncreaseSubClose()

					rerender(!render)




				} else if (result.status == 404) {
					toast.error(result.Message, {
						position: "top-center",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
						autoClose: false
					})


				}

			})

	}


	return (
		<div style={{ margin: "0 auto" }}>
			<PurgeConfirmation />
			<ReturnToCustomerClass toast={toast} customerID={customerID}	rerender= {rerender}  render={render} loggedBranch={loggedBranch}  subscriptionID={subscriptionID} remainingValue={remainingValue} openReturnCustomerClass={openReturnCustomerClass}  handleCloseReturnCustomerClass ={handleCloseReturnCustomerClass} setOpenRetrunCustomerClass={setOpenRetrunCustomerClass} handleOpenRetrunCustomerClass={handleOpenRetrunCustomerClass} />
			<div >

				<BootstrapDialog
					onClose={handleClose}
					aria-labelledby="customized-dialog-title"
					open={open}
				>
					  <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", fontFamily: "Poppins", width: "fit-content", margin: "auto" }} id="customized-dialog-title">
                    Change package
                </DialogTitle>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent style={{ width: "100%", height: "100%", padding: "40px" }}

						dividers>
							                    <form  onSubmit={(e)=>HandleGClassPackageChange(e)} style={{ display: "block",marginTop:"0",fontFamily:"poppins",paddingTop:"0", flexDirection: "column", backgroundColor:"white", width:"250px", height:"250px", margin:"auto" }}>

						{/* <select required onChange={(e) => setSelectedPackage(e.target.value)} name="" id=""> */}
						<select                                                      style={{ width:"90%", minWidth: "90%", textAlign: "center",fontFamily:"poppins",backgroundColor: "whitesmoke", color: "black", border: "none", border: "1px solid #e4e4e4", marginBottom: "15px", backgroundColor: "whitesmoke" }}
 required ref={changePackageRef} name="" id="">

							<option value="" selected disabled>Package</option>
							{classesPackages?.filter(p => {
								return (
									p.status == "active"
								)
							}).map(pack => {
								return (
									<option value={pack.ClassPackageID}> {pack.ClassPackageDesc} ({pack.price} EGP/{pack.SessionsCount} Sessions) </option>


								)
							})}
						</select>

						<input ref={discountRef} defaultValue="0" required type='text' placeholder='Discount Value' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} /> <br />
						<Button type="submit" style={{margin:"auto",marginTop:"15px", backgroundColor: "#006241", color: "white", fontWeight: "bold", width: "250px", borderRadius: "8px", height: "40px" }} autoFocus >
                        confirm
                    </Button>
</form>
					</DialogContent>
					<DialogActions>
						
					</DialogActions>
				</BootstrapDialog>



				<Dialog
					open={activationOpen}

					onClose={handleCloseActivation}
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseActivation}>cancel</Button>
						<Button onClick={updateGymSubStatus}>Activate</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					maxWidth="sm"
					open={debtOpen}
					onClose={handleDebtClose}
				>










					<DialogContent sx={{ margin: "0 auto" }}>
						<img style={{ width: "200px", margin: "0 auto", height: "200px" }} src="/pay.png" />
					</DialogContent>
					<DialogContent>


						<form onSubmit={(e) => PayClassDebt(e)} style={{ height: "100px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0" }}>
							<input ref={PayDebtValue} style={{ border: "1px solid  grey", alignItems: "cemter", textAlign: "center", borderRadius: "7px", marginTop: "4px" }} type='text' placeholder='Pay value' />
							<div style={{ display: "flex", margin: "0 auto", justifyContent: "space-evenly" }}>
								<Button onClick={handleDebtClose}>Close</Button>
								<LoadingButton
									style={{ backgroundColor: "#e4e4e4", marginTop: "0", color: "black", fontWeight: "bold" }}
									loading={buttonLoading}
									loadingPosition="start"
									type='submit'
									startIcon={<AttachMoneyIcon />}

								>
									Pay
								</LoadingButton>
							</div>


						</form>






					</DialogContent>








				</Dialog>



				<BootstrapDialog
					onClose={handleDeleteSubClose}
					open={deleteSub}
					aria-labelledby="customized-dialog-title"



				>
					<IconButton
						aria-label="close"
						onClick={handleDeleteSubClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>


					<DialogContent sx={{ marginLeft: "40px", marginRight: "40px", padding: "10px" }}>
						<h5 style={{ fontWeight: "bold", margin: "0 auto" }}> Cancel Subscription?</h5>
						<h8 style={{ marginLeft: "15px" }}> {remainingSessions} Remaining sessions</h8>


					</DialogContent>

					<DialogContent sx={{ margin: "0 auto" }}>
						<img style={{ width: "150px", margin: "0 auto" }} src='/cancelsub.png' />
					</DialogContent>

					<DialogContent sx={{ margin: "0 auto" }}>
						<form onSubmit={(e) => cancelClassSub(e)} style={{ height: "150px", width: "200px", backgroundColor: "white", padding: "10px", marginLeft: "0", border: "1px solid #e4e4e4", width: "auto" }}>
							<input ref={paybackvalue} required type='text' placeholder='Payback Value' style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid #e4e4e4", fontSize: "14px", margin: "0 auto", marginBottom: "10px" }} /> <br />
							<select ref={cancelCause} style={{ marginBottom: "7px", width: "200px", border: "1px solid #e4e4e4", alignItems: "center", textAlign: "center" }}>
								<option style={{ alignItems: "center", textAlign: "center" }} disabled selected>Cause</option>
								<option style={{ alignItems: "center", textAlign: "center" }} value="Retrun">Retrun</option>
								<option style={{ alignItems: "center", textAlign: "center" }} value="Wrong">Wrong</option>

							</select>
							<button type='submit' style={{ backgroundColor: "black", color: "white", margin: "0 auto" }} autoFocus >
								Confirm
							</button>
						</form>
					</DialogContent>





				</BootstrapDialog>



				<BootstrapDialog
					sx={{ padding: "50px" }}
					onClose={handleInvitationClose}
					aria-labelledby="customized-dialog-title"
					open={invtiationOpen}
				>

					<IconButton
						aria-label="close"
						onClick={handleInvitationClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<h4 style={{ margin: "10px auto", fontWeight: "bold" }}>Invitation</h4>
					<img style={{ width: "200px", margin: "0 auto" }} src="/invite.png" />
					<DialogContent sx={{ margin: "0 auto" }}>
						<form onSubmit={(e) => handleCustomerInvitation(e)} style={{ height: "120px", width: "300px", backgroundColor: "white", padding: "10px", marginLeft: "0", border: "1px solid #e4e4e4", padding: "5px" }}>
							<input ref={invitedCustomerName} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Name' /> <br />
							<input ref={invitedCustomerPhone} style={{ alignItems: "center", textAlign: "center", borderRadius: "8px", border: "1px solid grey", fontSize: "14px", margin: "0 auto", marginBottom: "10px", width: "200px" }} type="text" placeholder='Phone' /> <br />
							<Button type='submit' style={{ backgroundColor: "black", color: "white", fontWeight: "bold", margin: "0 auto", borderRadius: "10px" }} autoFocus onClick={handleClose}>
								Confirm
							</Button>
						</form>


					</DialogContent>

				</BootstrapDialog>

				{subcriptionData?.map(sub => {
					return (<ClassDataComponent sub={sub} />)

				})}


				<form style={{ width: '340px', height: "auto", margin: '0 auto', padding: "20px", backgroundColor: "white", border: "1px solid #e4e4e4" }}
					onSubmit={formik.handleSubmit}>
					<h5>Select Package</h5>

					<FormControl fullWidth margin="normal" error={Boolean(formik.touched.selectedFamily && formik.errors.selectedFamily)}>
						<InputLabel>Family</InputLabel>
						<Select
							name="selectedFamily"
							value={formik.values.selectedFamily}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						>
							<MenuItem value="" disabled>
								Family
							</MenuItem>
							{allClassesFamilies?.map(fam => (
								<MenuItem key={fam.ClassFamilyID} value={fam.ClassFamilyID}>
									{fam.Description}
								</MenuItem>
							))}
						</Select>
						{formik.touched.selectedFamily && formik.errors.selectedFamily && (
							<div style={{ color: 'red' }}>{formik.errors.selectedFamily}</div>
						)}
					</FormControl>

					<FormControl fullWidth margin="normal" error={Boolean(formik.touched.selectedPackID && formik.errors.selectedPackID)}>
						<InputLabel>Package</InputLabel>
						<Select
							name="selectedPackID"
							value={formik.values.selectedPackID}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						>
							<MenuItem value="" disabled>
								Package
							</MenuItem>
							{classesPackages?.filter(p => {
								return p.status == "active" && p.ClassFamilyID == formik.values.selectedFamily
							}).map(cPack => (
								<MenuItem key={cPack.ClassPackageID} value={cPack.ClassPackageID}>
									{cPack.price} EGP / {cPack.SessionsCount} sessions ({cPack.ClassPackageDesc})
								</MenuItem>
							))}
						</Select>
						{formik.touched.selectedPackID && formik.errors.selectedPackID && (
							<div style={{ color: 'red' }}>{formik.errors.selectedPackID}</div>
						)}
					</FormControl>

					<FormControl fullWidth margin="normal" error={Boolean(formik.touched.selectedPT && formik.errors.selectedPT)}>
						<InputLabel>Personal Trainer</InputLabel>
						<Select
							name="selectedPT"
							value={formik.values.selectedPT}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						>
							<MenuItem value="" disabled>
								Personal trainer
							</MenuItem>
							{PT?.map(pt => (
								<MenuItem key={pt.id} value={pt.fullName}>
									{pt.fullName}
								</MenuItem>
							))}
						</Select>
						{formik.touched.selectedPT && formik.errors.selectedPT && (
							<div style={{ color: 'red' }}>{formik.errors.selectedPT}</div>
						)}
					</FormControl>

					<TextField
						fullWidth
						margin="normal"
						label="Discount"
						name="discount"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.discount}
					/>

					<FormControlLabel
						control={
							<Button
								style={{ marginLeft: "20px", marginBottom: "10px" }}
								endIcon={<AttachMoneyIcon style={{ color: "red" }} />}
								variant="outlined"
								color="error"
								onClick={() => formik.setFieldValue('debt', !formik.values.debt)} // Toggle debt state
							>
								Debt
							</Button>
						}
					/>

					<Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
						<Fade in={formik.values.debt}>
							<Paper>
								<TextField
									fullWidth
									margin="normal"
									label="Paid"
									name="paid"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.paid}
									error={Boolean(formik.touched.paid && formik.errors.paid)}
								/>
								{formik.touched.paid && formik.errors.paid && (
									<div style={{ color: 'red' }}>{formik.errors.paid}</div>
								)}
								{formik.values.paid != "" && <FormControl fullWidth margin="normal" error={Boolean(formik.touched.graceDays && formik.errors.gracePeriod)}>
									<InputLabel>Grace period (days)</InputLabel>
									<Select
										name="graceDays"
										value={formik.values.graceDays}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									>
										<MenuItem value="" disabled>
											Grace period
										</MenuItem>
										{days_list.map(d => (
											<MenuItem key={d} value={d}>
												{d}
											</MenuItem>
										))}
									</Select>
									{formik.touched.graceDays && formik.errors.graceDays && (
										<div style={{ color: 'red' }}>{formik.errors.graceDays}</div>
									)}
								</FormControl>}
							</Paper>
						</Fade>
					</Box>

					<LoadingButton
						sx={{
							height: "40px",
							fontWeight: "bold",
							borderRadius: "12px",
							backgroundColor: "#152238",
							width: "250px",
							color: "white",
							margin: "auto",
							":hover": {
								backgroundColor: "#152238",
							},
						}}
						type="submit" // Change to submit
						loading={btnLoading}
						variant="contained"
					>
						Submit
					</LoadingButton>
				</form>
			</div>
		</div>


	)
}


export default ClassesData