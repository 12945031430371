import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import SvgIcon from '@mui/joy/SvgIcon';
import moment from 'moment-timezone';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const API = process.env.REACT_APP_API;

const theme = createTheme();

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#f1f1f1',
  border: 'none',
  padding: 0,
  width: "400px",
  height: "250px"
}));

function AllFinanceByTime() {
  const [loading, setLoading] = useState(true);
  const [gymPaid, setGymPaid] = useState();
  const [gymRemaining, setGymRemaining] = useState();

  const [academyPaid, setAcademyPaid] = useState();
  const [academyRemaining, setAcademyRemaining] = useState();
  const [classPaid, setClassPaid] = useState();
  const [classRemaining, setClassRemaining] = useState();
  const [dietPaid, setDietPaid] = useState();
  const [startDate, setStartDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [branches, setBranches] = useState([]);
  const [branchesLoading, setBranchesLoading] = useState(false);
  const [ClinicRemaining, setClinicRemaining] = useState();
  const [ClinicPaid, setClinicPaid] = useState();
const [rent, setRent] = useState()


  useEffect(() => {
    const fetchFinanceData = async () => {
      setLoading(true);
      const response = await fetch(`${API}/AllFinanceByTime?startDate=${startDate}&endDate=${endDate}&branch=${selectedBranch}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });


      const result = await response.json();

      console.log(result)

      setGymPaid(result.GYMPaid);
      setGymRemaining(result.GYMRemaining);
      setAcademyPaid(result.AcademyPaid);
      setAcademyRemaining(result.AcademyRemaining);
      setClassPaid(result.ClassPaid);
      setClassRemaining(result.ClassRemainig);
      setClinicPaid(result.ClinicPaid);
      setClinicRemaining(result.ClinicRemaining);
setDietPaid(result.Diet)
setRent(result.rent)
      setLoading(false);

      console.log()
    };
    fetchFinanceData();
  }, [startDate, endDate, selectedBranch]);


  useEffect(() => {
    const fetchBranches = async () => {
      setBranchesLoading(true);
      const response = await fetch(`${API}/branches`);
      const data = await response.json();
      setBranches(data.branches);
      setBranchesLoading(false);
    };
    fetchBranches();
  }, []);

  const print = () => window.print();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ margin: '0 auto', width: '100%', padding: 2, backgroundColor:"#edf0f7" }}>
        <Box sx={{ display: 'block', margin: "auto", width: "70%", marginBottom:"30px", boxShadow: `0px 4px 20px #e4e4e4`, justifyContent: 'space-between', alignItems: 'center' }}>

          <Box sx={{ textAlign: 'center', width: "100%",backgroundColor:"white",padding: "20px", borderRadius: "20px" }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', borderRadius: 1, padding: 1 }}>
              <DatePicker style={{backgroundColor:"#edf0f7"}} format={"YYYY-MM-DD"} onChange={(date, dateString) => setStartDate(dateString)} />
              <DatePicker  style={{backgroundColor:"#edf0f7"}} format={"YYYY-MM-DD"} onChange={(date, dateString) => setEndDate(dateString)} />
            </Box>
            <Box sx={{ margin: 2 }}>
              {branchesLoading ? (
                <CircularProgress />
              ) : (
                <select onChange={(e) => setSelectedBranch(e.target.value)} style={{ width: '20%', padding: 8, textAlign: "center", alignItems: "center", borderRadius: "7px", backgroundColor:"#edf0f7", border:"1px solid #edf0f7" }}>
                  <option value="" disabled selected>Branch</option>
                  <option value="All">All</option>
                  {branches.map(branch => (
                    <option key={branch.id} value={branch.name}>{branch.name}</option>
                  ))}
                </select>
              )}

            </Box>
            <Typography sx={{ width: "fit-content"  , margin:"auto"}} variant="body1">
              <b>{selectedBranch}</b> revenue between <b>{startDate}</b> and <b>{endDate}</b>
            </Typography>
            <Button
              sx={{ color: 'black', width: "20%",backgroundColor: '#edf0f7' ,fontWeight:"bold", borderRadius: 1, fontSize: 15 ,
                '&:hover' :{
                  backgroundColor: '#027fb4',
                }
              }}
              endIcon={<LocalPrintshopIcon sx={{ color: 'black' }} />}
              onClick={print}
            >
              Print
            </Button>

          </Box>
        </Box>

        {/* <Paper
					elevation={0}
					sx={{
						padding: '10px',
						backgroundColor: "#edf0f7",
						border: "1px solid #e4e4e4",
						color: "black",
						borderRadius: '10px',
						display: 'block',
						textAlign: "center",
						alignItems: 'center',
						fontFamily: "poppins",
						width: "250px",
						height: "90px",
						display: "flex",
						justifyContent: "center",

						boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
					}}
				>
					<Box sx={{ display: "block", width: "100%", }}>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", margin: "auto", width: "fit-content" }}>
							<PaidIcon sx={{ fontSize: "30px" }} />
							<Typography sx={{ fontWeight: "bold", fontFamily: "poppins", fontSize: "23px" }}>
								Total Remaining
							</Typography>
						</Box>
						<Typography sx={{ fontWeight: "bold", fontFamily: "poppins" }}>
							{sumRemaining} EGP
						</Typography>
					</Box>

				</Paper> */}
        <Box sx={{
          boxShadow: `0px 4px 20px #e4e4e4`, // Increased drop shadow
          display: 'flex',
          flexDirection: 'row',
          width:"70%",
          backgroundColor:"white",
          flexWrap: 'wrap', // Allows items to wrap to the next line
          justifyContent: 'space-between', // Space between items
          marginTop: 2,
          padding:"20px",
          minHeight:"100px",
          margin: 'auto',
          borderRadius:"10px"
        }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {[{ label: 'GYM', paid: gymPaid, remaining: gymRemaining , icon:<FitnessCenterIcon style={{color:'white'}} />}, { label: 'Class', paid: classPaid, remaining: classRemaining }, { label: 'Academy', paid: academyPaid, remaining: academyRemaining }, { label: 'Diet', paid: dietPaid, remaining: null },{ label: 'Rent', paid: rent, remaining: null }, { label: 'Clinic', paid: ClinicPaid, remaining: ClinicRemaining }, { label: 'Total', paid: Number(ClinicPaid)  + Number(academyPaid) + Number(gymPaid) + Number(classPaid) + Number(dietPaid) + Number(rent), remaining: Number(ClinicRemaining) + Number (academyRemaining) +  Number(gymRemaining) + Number(classRemaining)  }].map((item, index) => (
        <Paper
        elevation={0}
        className="paper"  // Add class for print-specific styles
        sx={{
          padding: '10px',
          backgroundColor: "#edf0f7",
          color: "black",
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontFamily: "Poppins",
          maxWidth: "100%",
          minWidth: "200px",
          minHeight: "120px",
          maxHeight: "auto",
          height: "auto",
          margin: "5px",
          padding: "10px",
          overflow: 'hidden',
          wordWrap: 'break-word',
          '@media (max-width: 600px)': {
            padding: '8px',
            fontSize: '14px',
          }
        }}
        key={index}
      >
        <h3
          className="break-inside-avoid"  // Add class to prevent breaking of title
          style={{
            fontFamily: "Poppins",
            marginBottom: 2,
            color: "#128fc8",
            margin: "auto",
            width: "fit-content",
            wordSpacing: "3px",
            fontSize: "15px",
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {item.label}
        </h3>
  
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: "4px", alignItems: 'center', width: '70%' }}>
          <Box backgroundColor='#4caf50' padding="2px" borderRadius="8px">
            <CheckCircleOutlineIcon sx={{ color: "white", fontSize: 20 }} />
          </Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "18px", color: "#4caf50", fontFamily: "Poppins" }}>
            {item.paid} EGP
          </Typography>
        </Box>
  
        {item.remaining && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%', marginTop: 1 }}>
            <Box backgroundColor='#f44336' padding="2px" borderRadius="8px">
              <WarningAmberIcon sx={{ color: 'white', fontSize: 20 }} />
            </Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "18px", color: "#f44336", fontFamily: "Poppins" }}>
              {item.remaining} EGP
            </Typography>
          </Box>
        )}
      </Paper>
              ))}
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AllFinanceByTime;
