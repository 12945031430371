import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Badge
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import DeleteIcon from '@mui/icons-material/Delete';
import { Result } from 'antd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const API = process.env.REACT_APP_API


const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];
function Tenants() {

	const [rentData, setData] = React.useState()
	const [loading, setLoading] = React.useState()
	const [rentID, setRentID] = React.useState()
	const [value, setValue] = React.useState(true)
	const [categoryID, setCategoryID] =React.useState()
	const [open, setOpen] = React.useState(false);
	const [deleteOpen, setDeleteOpen] = React.useState(false);

    const Role = localStorage.getItem("Roles")

	const [render, setRender] = React.useState(value)
	function handleID(x, y) {
		setOpen(true);
		setRentID(x)
		setCategoryID(y)


	}

	function handleDeleteID(x) {
		setDeleteOpen(true);
		setRentID(x)


	}
	


	const handleClose = () => {
		setOpen(false);
	};


	const handleCloseDelete = () => {
		setDeleteOpen(false);
	};

	React.useEffect(function () {
		async function fetchDate() {
			try {
				setLoading(true)
				const res = await fetch(`${API}/getTentants`)
				const data = await res.json()
				setData(data.rent)
			} catch (e) {
				console.log(e)
			} finally {
				setLoading(false)
			}
		}
		fetchDate()
	}, [render])



	function deleteTenant() {

		fetch(`${API}/deleteRent`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'id': rentID,
				"categoryID" : categoryID

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

				}
			})
		setRender(!render)

		setOpen(false);


	}

	
	function purgeTenant() {

		fetch(`${API}/purgeRent`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'id': rentID,

			})
		}).then(response => response.json())
			.then(result => {
				if (result.status == "200") {
					toast.success(`${result.Message}`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});

				}
			})
			setRender(!render)

		setDeleteOpen(false);


	}

	

		{if (loading) return <Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
		<CircularProgress />
	</Box>}


	
		return (
			<Box>

			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={deleteTenant} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>



			<Dialog
				open={deleteOpen}
				onClose={handleCloseDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure you want to entirely delete the rent?"}
				</DialogTitle>
				<DialogContent>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDelete}>Cancel</Button>
					<Button onClick={purgeTenant} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<TableContainer sx={{ width: "70%", margin: "0 auto" }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold", fontFamily:"Poppins" }} align="center">Name</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold", fontFamily:"Poppins" }} align="center">Phone</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold" , fontFamily:"Poppins"}} align="center">Address</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4', fontSize:"14px",fontWeight: "bold" , fontFamily:"Poppins"}} align="center">Category</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4', fontSize:"14px",fontWeight: "bold" , fontFamily:"Poppins"}} align="center">Paid</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold" , fontFamily:"Poppins"}} align="center">Start Date</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold", fontFamily:"Poppins" }} align="center">End Date</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold", fontFamily:"Poppins" }} align="center">Status</TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold", fontFamily:"Poppins" }} align="center"></TableCell>
                        <TableCell sx={{ backgroundColor: '#e4e4e4',fontSize:"14px", fontWeight: "bold", fontFamily:"Poppins" }} align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rentData?.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell  sx={{fontFamily:"Poppins", fontWeight:"bold"}} align="center">{row.Name}</TableCell>
                            <TableCell  sx={{fontFamily:"Poppins", fontWeight:"bold"}}align="center">{row.Phone}</TableCell>
                            <TableCell  sx={{fontFamily:"Poppins", fontWeight:"bold"}}align="center">{row.Address}</TableCell>
                            <TableCell  sx={{fontFamily:"Poppins", fontWeight:"bold"}}align="center">{row.Category}</TableCell>
                            <TableCell sx={{fontFamily:"Poppins", fontWeight:"bold"}} align="center">{row.Paid}</TableCell>
                            <TableCell  sx={{fontFamily:"Poppins", fontWeight:"bold"}}align="center">{row.StartDate}</TableCell>
                            <TableCell  sx={{fontFamily:"Poppins", fontWeight:"bold"}}align="center">{row.EndDate}</TableCell>
                            <TableCell  sx={{fontFamily:"Poppins", fontWeight:"bold"}}align="center">
                                <Badge
							
                                    color={row.status === "Rented" ? "success" : "error"}
                                    badgeContent={row.status}
                                    sx={{ cursor: "default",	fontWeight:"bold"
									 }}
                                />
                            </TableCell>
							{Role == "admin"|| Role == "Admin" || Role == "Receptionist" ? ( <TableCell align="center">
								<DeleteIcon  sx={{color:"white", backgroundColor:"darkred", borderRadius:"5px", height:"30px", width:"30px"}}
                                   onClick={() => handleDeleteID(row.id)} style={{ cursor: "pointer" }} />
                                </TableCell>):("")}
                               

								{(row.status !== "Ended" && (Role === "admin" || Role === "Admin" || Role === "Receptionist")) ? (
  <TableCell align="center">
    <HighlightOffIcon
      sx={{ color: "white", backgroundColor: "#203354", borderRadius: "5px", height: "30px", width: "30px" }}
      onClick={() => handleID(row.id, row.rentcategoryID)}
      style={{ cursor: "pointer" }}
    />
  </TableCell>
) : null}

                        
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
		</Box>
		)
	
}

export default Tenants