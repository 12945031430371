import Multiselect from 'multiselect-react-dropdown';
import { useState, useEffect } from 'react';
import "../../styles/classStyles/class.css"
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar';
import ClassNav from '../../components/classNav';
function AddClassFamilyPage() {

    const [classfamily, setClassfamily] = useState()
    const [classLoading, setClassLoading] = useState()
    const [classesFamily, setClassesFmily] = useState([])

    const [classes, setClasses] = useState([])
    const navigate = useNavigate()
    const [selectedClasses, setSelectedClasses] = useState();
    const [loading, setLoading] = useState()

    useEffect(function () {
        async function fetchClassFamily() {
            try {
                setLoading(true)
                const res = await fetch(`${API}/getAllClassFamilies`)
                const data = await res.json()
                setClassesFmily(data.data)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)


            }
        }
        fetchClassFamily()
    }, [])

    function handleClassSelection(select_class) {
        setSelectedClasses(select_class);
    }


    useEffect(function () {
        async function fetcData() {
            try {
                setClassLoading(true)
                const res = await fetch(`${API}/getAllClasses`)
                const data = await res.json()
                setClasses(data.result)
                console.log("fetched")
            } catch (e) {
                console.log(e)
            } finally {
                setClassLoading(false)


            }
        } fetcData()
    }, [])


    const customStyles = {
        control: base => ({
            ...base,
            height: 35,
            minHeight: 35
        })
    };
    const API = process.env.REACT_APP_API

    function handleAddClass(e) {
        e.preventDefault()
        fetch(`${API}/addClassFamily`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'description': classfamily,
                "classes": selectedClasses

            })
        }).then(response => response.json())
            .then(result => {
                if (result.status == 200) {
                    toast.success(`${result.Message}`, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    // setTimeout(() => {
                    //     navigate("/add-class")
                    // }, 2000)
                } else {
                    toast.error(`${result.Message}`, {
                        position: "top-center",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }
    return (
        <>


            <div className="add-class-page">

                <ToastContainer
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

                <form style={{height:"200px", padding:"10px", backgroundColor:"white"}} onSubmit={(e) => handleAddClass(e)}>
                    <h2 style={{top:"20px"}}>Add class family</h2>
                    <input type="text" placeholder='Family name' onChange={(e) => setClassfamily(e.target.value)} />

                    <button style={{margin:"0 auto"}}>Add</button>

                </form>
            </div></>
    )
}

export default AddClassFamilyPage