import { useState } from "react";

import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Badge,
    Button
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';

import { ToastContainer, toast } from 'react-toastify';

import AutorenewIcon from '@mui/icons-material/Autorenew';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


function RentCategory() {



    const [categories, setCategories] = useState()
    const [loading, setLoading] = useState()
    const [categoryID, setCategoryID] = useState()
    const [render, setRender] = useState(false)
    const [open, setOpen] = React.useState(false);
    const Role = localStorage.getItem("Roles")

    const API = process.env.REACT_APP_API
	const handleClose = () => {
		setOpen(false);
	};


    function handleCategoryStatus(id) {
        console.log(id)
            fetch(`${API}/rentCategoryStatus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'id': id,
                    // 'price': price,
                })
            }).then(response => response.json())
                .then(result => {
                    if (result.status == 200) {
                        
                        toast.success(`${result.Message}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                        setTimeout(() => {
                         setRender(!render)   
                        }, 1000);
                    } else {
                        toast.error(`${result.Message}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                })
        }


        function handleDeleteCategory() {
                fetch(`${API}/deleteRentCategory`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'id': categoryID,
                        // 'price': price,
                    })
                }).then(response => response.json())
                    .then(result => {
                        if (result.status == 200) {
                            
                            toast.success(`${result.Message}`, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                            setOpen(false)

                            setTimeout(() => {
                             setRender(!render)   
                            }, 500);
                        } else {
                            toast.error(`${result.Message}`, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        }
                    })
            }
    useEffect(function () {
        async function fetchDate() {
            try {
                setLoading(true)
                const res = await fetch(`${API}/getRentCategories`)
                const data = await res.json()
                setCategories(data.categories)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        } fetchDate()
    }, [render])


    {if (loading) return <Box sx={{ display: 'flex', margin: "0 auto", width: "10%" }}>
    <CircularProgress />
</Box>}    return (

        <>
<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleDeleteCategory} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>

            <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
            <TableContainer sx={{ width: "70%", margin: "0 auto", borderRadius: "10px" }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: '#e4e4e4', fontWeight: "bold", fontFamily: "Poppins" }} align="center">Category</TableCell>
                            {/* <TableCell sx={{ backgroundColor: '#e4e4e4', fontWeight: "bold", width: "30%", fontFamily: "Poppins" }} align="center">Status</TableCell> */}
                            <TableCell sx={{ backgroundColor: '#e4e4e4', fontWeight: "bold", width: "30%", fontFamily: "Poppins" }} align="center"></TableCell>
                            <TableCell sx={{ backgroundColor: '#e4e4e4', fontWeight: "bold", width: "30%", fontFamily: "Poppins" }} align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories?.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell style={{ fontWeight: "bold", fontFamily: "Poppins" }} align="center">{row.name} 

                                </TableCell>


                                {/* <TableCell align="center">
                                    <Badge
                                        color={row.status === "Rented" ? "error" : "success"}
                                        badgeContent={row.status}
                                        sx={{ cursor: "default" }}
                                    />
                                </TableCell> */}

{Role == "admin" || Role == "Admin" ? ( <TableCell align="center">
                                        <Button
                                            variant="contained"
                                            sx={{ width:"150px", backgroundColor: 'darkred', borderRadius: "7px", color: 'white' }}
                                            startIcon={<DeleteIcon sx={{ color: 'white' }} />}
                                            onClick={() => {setCategoryID(row.id); setOpen(true)}}>
                                            Delete
                                        </Button>
                                    </TableCell>):("")}
                                   

                                {Role == "admin" || Role == "Admin" ? (<TableCell>
                                    <Button
                                        variant="contained"
                                        sx={{width:"150px", backgroundColor: row.availability === "active" ? '#4cc764' : '#e4e4e4', borderRadius: "7px", color: 'white' }}
                                        startIcon={<AutorenewIcon sx={{ color: 'white' }} />}
                                        onClick={() => handleCategoryStatus(row.id)}>
                                        {row.availability == "active" ? ("deactivate") : ("activate")}
                                    </Button>
                                </TableCell>
):("")}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}


export default RentCategory