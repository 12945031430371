
import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import qs from 'qs';
import moment from 'moment-timezone';

import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import "../../styles/attendanceTime.css"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Route, Link, Outlet } from 'react-router-dom';
import { DatePicker, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { FaPerson } from "react-icons/fa6";
import { LineChart } from '@mui/x-charts/LineChart';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import Chart from 'chart.js/auto';
import { MdOutlinePlace } from "react-icons/md";
import { Line } from "react-chartjs-2";
import { MdAccountCircle } from "react-icons/md";
import { hoursToMilliseconds } from 'date-fns';

function AcademyAttendance() {
	const API = process.env.REACT_APP_API

	const [value, setValue] = useState('1');
	const [attendaners, setAttenders] = useState()
	const [endDate, setEnDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"))
	const [startDate, setDate] = useState(moment().tz("Africa/Cairo").format("YYYY-MM-DD"));
	const [AttData, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [startTime, setStartTime] = useState(useState(moment().tz("Africa/Cairo").format("HH:mm:ss")))
	const [endTime, setEndTime] = useState(useState(moment().tz("Africa/Cairo").format("HH:mm:ss")))
	const [hourRange, setHourRange] = useState([])
	const [proceed, setProceed] = useState()


	const academies = []
	const clock = moment().tz("Africa/Cairo").format("HH:mm:ss")
	const format = 'HH:mm:ss';
	const dates = []

	console.log(AttData)

	//Get attendnce durations

function getHours() {
	AttData.filter(m => {
		dates.push(m.Date.split(" ")[1])
	})

}getHours()



function filterClasses() {
	AttData.map(m => {
		const check = academies.find(o => o.text == m.description)
		console.log(check)
		if (check == undefined) {
			academies.push({
				text: m.description,
				value: m.description,
			})
			
		} 
		
	})



} filterClasses()



	function countAttendantsByHour(dates) {
		// Initialize time ranges from 08:00:00 to 08:00:00 the next day
		const startHour = 8;
		const endHour = startHour + 24; // 24-hour period to cover until 08:00:00 next day
		const hourlyCounts = {};
	
		// Create hourly ranges
		for (let hour = startHour; hour < endHour; hour++) {
			const hourStr = (hour % 24).toString().padStart(2, '0');
			const nextHourStr = ((hour + 1) % 24).toString().padStart(2, '0');
			const rangeStart = `${hourStr}:00:00`;
			const rangeEnd = `${nextHourStr}:00:00`;
			hourlyCounts[`${rangeStart} - ${rangeEnd}`] = 0;
		}
	
		// Count attendants within each time range
		dates.forEach(record => {
			const timeStr = record;
			const hour = parseInt(timeStr?.split(':')[0], 10);
			const hourStr = hour.toString().padStart(2, '0');
			const nextHourStr = ((hour + 1) % 24).toString().padStart(2, '0');
	
			const rangeKey = `${hourStr}:00:00 - ${nextHourStr}:00:00`;
			if (hour >= startHour || hour < (startHour + 24) % 24) {
				if (hourlyCounts.hasOwnProperty(rangeKey)) {
					hourlyCounts[rangeKey]++;
				}
			}
		});
	
		// Convert the result to an array of objects
		const result = Object.entries(hourlyCounts).map(([range, count]) => ({
			range: range,
			count: count
		}));
	
		return result;
	}
	
	const result = countAttendantsByHour(dates);
	console.log(result);




	const count_from_8am_to_9am = result[0].count
	const count_from_9am_to_10am = result[1].count
	const count_from_10pm_to_11pm = result[2].count

	const count_from_11pm_to_12pm = result[3].count
	const count_from_12pm_to_13pm = result[4].count
	const count_from_13pm_to_14pm = result[5].count

	const count_from_14pm_to_15pm = result[6].count
	const count_from_15am_to_16am = result[7].count
	const count_from_16pm_to_17pm = result[8].count
	const count_from_17pm_to_18pm = result[9].count
	const count_from_18pm_to_19pm = result[10].count
	const count_from_19pm_to_20pm = result[11].count
	const count_from_20pm_to_21pm = result[12].count
	const count_from_21pm_to_22pm = result[13].count
	const count_from_22pm_to_23pm = result[14].count
	const count_from_23pm_to_24pm = result[15].count
	const count_from_00pm_to_01pm = result[16].count
	const count_from_01pm_to_02pm = result[17].count
	const count_from_02pm_to_03pm = result[18].count
	const count_from_03pm_to_04pm = result[19].count
	const count_from_04pm_to_05pm = result[20].count
	const count_from_05pm_to_06pm = result[21].count
	const count_from_07pm_to_08pm = result[22].count




	const LineData = {
		labels: ["8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM", "12PM"],
		datasets: [

			{
				label: "Attendance Rate",
				data: [0, 
					count_from_8am_to_9am,
					count_from_9am_to_10am,
					count_from_10pm_to_11pm,
					count_from_11pm_to_12pm,
					count_from_12pm_to_13pm,
					count_from_13pm_to_14pm,
					count_from_14pm_to_15pm,
					count_from_15am_to_16am,
					count_from_16pm_to_17pm,
					count_from_17pm_to_18pm,
					count_from_18pm_to_19pm,
					count_from_19pm_to_20pm,
					count_from_20pm_to_21pm,
					count_from_21pm_to_22pm,
					count_from_22pm_to_23pm,
					count_from_23pm_to_24pm,
					count_from_00pm_to_01pm,
					count_from_01pm_to_02pm,
					count_from_02pm_to_03pm,
					count_from_03pm_to_04pm,
					count_from_04pm_to_05pm,
					count_from_05pm_to_06pm,
					count_from_07pm_to_08pm




				
			
				
				],
				fill: false,
				borderColor: "#742774"
			}
		]
	};

	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 10,
		},

		date: {
			startDate: startDate,
			endDate: endDate

		}


	});


	const male = []
	const female = []
	const energy = []
	const city = []

	AttData.map(m => {
		if (m.Gender == "Male") {
			male.push(m)
		} else if (m.Gender == "Female") {
			female.push(m)
		}
	})

	AttData.map(m => {
		if (m.Branch == "Energy" || m.Branch == "energy") {
			energy.push(m)
		} else if (m.Branch == "City Club") {
			city.push(m)
		}
	})



	useEffect(function () {
		function timeRange() {
			const filtered_hours = AttData.filter(m => {
				const hour = m.Date.split(" ")[1]


				return (
					startTime <= hour && hour <= endTime
				)




			})

			setHourRange(filtered_hours)

		} timeRange()

	}, [endTime])


	const onStartTimeChange = (time, timeString) => {
		console.log(timeString)
		setStartTime(timeString);
	};

	const onEndTimeChange = (time, timeString) => {
		console.log(timeString)

		setEndTime(timeString);
	};

	const data = [
		{ id: 0, value: male.length, label: 'Male' },
		{ id: 1, value: female.length, label: 'Female' },
	];
	const branchData = [
		{ id: 0, value: energy.length, label: 'Energy' },
		{ id: 1, value: city.length, label: 'City Club' },
	];
	const size = {
		width: 400,
		height: 200,
	};


	const columns = [

		{
			title: 'Customer ID',
			dataIndex: 'CustomerID',
		},



		{
			title: 'Name',
			align: 'center',

			dataIndex: 'Fullname',
			sorter: true,
			render: (Fullname) => `${Fullname}`,
			width: '20%',
		},
		{
			title: 'National ID',
			dataIndex: 'NationalID',
		},
		{
			title: 'Phone',
			dataIndex: 'Phone',
		},
		{
			title: 'Gender',
			dataIndex: 'Gender',
			align: 'center',

			filters: [
				{
					text: 'Male',
					value: 'Male',
				},
				{
					text: 'Female',
					value: 'Female',
				},
			],
			width: '20%',
		},

		{
			title: 'Branch',
			dataIndex: 'Branch',
			align: 'center',
			filters: [
				{
					text: 'Energy',
					value: 'Energy',
				},
				{
					text: 'City Club',
					value: 'City Club',
				},
			],
		},
		{
			title: 'Academy',
			dataIndex: 'description',
			align: 'center',
			filters: academies
		},

		{
			title: 'Date',
			dataIndex: "Date",
			key: "date",
			align: 'center',

		},


	];


	const handleChange = (event, newValue) => {
		setValue(newValue);
	}



	const onStartChange = (date, dateString) => {
		setDate(dateString)

		setTableParams({
			...tableParams,

			date: {
				...tableParams.date,
				startDate: dateString

			},
		});
	};

	const onEndChange = (date, dateString) => {

		// setEnDate(dateString);
		setEnDate(dateString)

		setTableParams({
			...tableParams,

			date: {
				...tableParams.date,
				endDate: dateString,

			},




		});
	};

	const getRandomuserParams = (params) => ({
		results: params.pagination?.pageSize,
		page: params.pagination?.current,
		...params,

	});


	const fetchData = () => {
		setLoading(true);
		fetch(`${API}/getAllAcademyAttendance?${qs.stringify(getRandomuserParams(tableParams))}`)
			.then((res) => res.json())
			.then((results) => {
				console.log(results)
				setAttenders(results.data.length)
				setData(results.data);
				setProceed(!proceed)
				setLoading(false);
				setTableParams({
					...tableParams,
					pagination: {
						...tableParams.pagination,
						total: 200,

					},
					date: {
						...tableParams.date,
						total: 200,

					},



				});
			});
	};
	useEffect(() => {
		fetchData();
	}, [
		tableParams.pagination?.current,
		tableParams.pagination?.pageSize,
		tableParams?.sortOrder,
		tableParams?.sortField,
		tableParams.date?.startDate,
		tableParams.date?.endDate,


		JSON.stringify(tableParams.filters),



	]);
	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			date: {
				startDate,
				endDate,
			},
			filters,
			sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
			sortField: Array.isArray(sorter) ? undefined : sorter.field,
		});

		// `dataSource` is useless since `pageSize` changed
		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setData([]);
		}
	};


	return (
		<div style={{ border: "1px solid #e4e4e4", padding: "20px", marginTop: "20px", backgroundColor: "#eaf2ff", borderRadius: "20px", width: "auto", height: "auto" }}>
			<div style={{ backgroundColor: "#ffffff", boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)", borderRadius: "7px", padding: "14px", marginBottom: "10px", border: "2px solid #fefefe" }}>
				<div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
					<DatePicker format="YYYY-MM-DD" placeholder='From' onChange={onStartChange} />
					<DatePicker format="YYYY-MM-DD" placeholder='To' onChange={onEndChange} />
				</div>

				<Card sx={{ margin: "0 auto", marginBottom: "30px", width: "130px", display: "flex", flexDirection: "row", color: "black", border: "1px solid #e4e4e4" }} variant="outlined">
					<CardContent sx={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", width: "auto", justifyContent: "center" }}>
						<Typography level="title-md" textColor="inherit">
							<AccountCircleIcon fontSize='large' />
						</Typography>
						<span style={{ fontWeight: "bold", color: "black", height: "fit-content", fontSize: "20px" }}>{attendaners}</span>
					</CardContent>
				</Card>

				<div style={{ width: "100%", border: "3px solid #e4e4e4", borderRadius: "10px", padding: "10px", marginBottom: "20px" }}>

					<Table

						columns={columns}
						// rowKey={(record) => record.login.uuid}
						dataSource={AttData}
						pagination={tableParams.pagination}
						loading={loading}
						onChange={handleTableChange}
						bordered
						scroll={{ x: 'max-content' }}
					/>
				</div>

			</div>






			<div style={{ width: "100%", backgroundColor: "#ffffff", borderRadius: "10px", padding: "20px", marginBottom: "10px", display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around", boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)" }}>
				<div style={{ width: "30%", alignItems: "center", textAlign: "center", backgroundColor: "#5a8fff", borderRadius: "7px", height: "120px", width: "100px", display: "block" }}>
					<h5 style={{ fontWeight: "bold", color: "#ffffff", marginTop: "7px" }}>Gender</h5>
					<FaPerson style={{ color: "#ffffff", marginTop: "10px" }} size={60} />

				</div>
				<div style={{ width: "50%", alignItems: "center", textAlign: "center" }}>
					<PieChart
						series={[
							{
								data,
								highlightScope: { faded: 'global', highlighted: 'item' },
								faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
							},
						]}
						height={200}
						width={400}
					/>
				</div>




			</div>



			<div style={{ width: "100%", backgroundColor: "#ffffff", borderRadius: "10px", padding: "10px", marginBottom: "10px", display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around", boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)" }}>
				<div style={{ width: "30%", alignItems: "center", textAlign: "center", backgroundColor: "#5a8fff", borderRadius: "7px", height: "120px", width: "100px", display: "block" }}>
					<h5 style={{ fontWeight: "bold", color: "#ffffff", marginTop: "7px" }}>Branch</h5>
					<MdOutlinePlace style={{ color: "#ffffff", marginTop: "10px" }} size={60} />

				</div>
				<div style={{ width: "50%", alignItems: "center", textAlign: "center" }}>


					<PieChart

						series={[
							{
								data: [
									{ id: 0, value: energy.length, label: 'Energy' },
									{ id: 1, value: city.length, label: 'City Club' },
								],
							},
						]}
						height={200}
						width={400}
						sx={{ margin: "0 auto" }}

					/>
				</div>
			</div>

			<div style={{ display: "block", justifyContent: "center", borderRadius: "7px", boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)", backgroundColor: "#ffffff", marginBottom: "20px" }}>

				<div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
					<TimePicker format={format} onChange={onStartTimeChange} defaultValue={dayjs('12:08:23', 'HH:mm:ss')} size="large" />
					<TimePicker format={format} onChange={onEndTimeChange} defaultValue={dayjs('12:08:23', 'HH:mm:ss')} size="large" />
				</div>
				<h5 style={{ margin: "0 auto", width: "max-content", marginTop: "10px", marginBottom: "10px", padding: "10px" }}>  <MdAccountCircle size={30} /> {hourRange.length} Attendant between {startTime} and {endTime}</h5>

			</div>

			<div style={{ width: "100%", backgroundColor: "#ffffff", borderRadius: "10px", padding: "10px", marginBottom: "10px", display: "flex", alignItems: "center", textAlign: "center", justifyContent: "space-around", boxShadow: "0px 7px 30px 5px rgba(0,0,0,0.1)" }}>





				<Line data={LineData} />


			</div>



		</div>
	)
}


export default AcademyAttendance